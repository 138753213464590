<template>

    <div class="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 h-screen bg-[#5FBAE6]">
      <div class="left-side bg-[#5FBAE6] flex justify-center items-center py-4 hidden md:flex">
  
        <div class="flex flex-col items-center">
          <div class="top-logo-banner">
            <img src="@/assets/img/logo-hwang.png" alt="logo-hwang" class="h-[128px] w-[128px]">
          </div>
          <div class="banner-img">
  
            <img src="@/assets/img/login-img-edit.png" alt="banner-login" class="h-[465px] w-[812px]">
          </div>
        </div>
      </div>
      <div class="right-side p-4 md:p-0 lg:p-0">
        <div class="flex flex-col items-center  h-full pt-2 justify-center">
          <div class="w-full bg-[#F5CA40F2]/[0.95] mt-8 rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 ">
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
              <div class="text-center mb-8 pt-6">
                <h1 class="text-sm  md:text-[40px] font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
                  Changes Password
                </h1>
              </div>
              <form class="" @submit.prevent="changePassword">
                <div class="mb-8">
                  <label for="password" class="block mb-2 text-lg font-redHat text-gray-900">
                    New Password <span class="text-red-500 font-redHat">*</span>
                  </label>
                  <input type="password" v-model="newpassword" class="bg-gray-50 border border-white text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" placeholder="" required />
                </div>

                <div class="mb-8">
                  <label for="password" class="block mb-2 text-lg font-redHat text-gray-900">
                    Confirm New Password <span class="text-red-500 font-redHat">*</span>
                  </label>
                  <input type="password" v-model="confirmnewpassword" class="bg-gray-50 border border-white text-gray-900 sm:text-sm rounded-lg focus:ring-blue-600 focus:border-blue-600 block w-full p-2.5" placeholder="" required />
                </div>

  
                <!-- for test only -->
                <!-- <RouterLink :to="{ name: 'dashboard' }">
                          </RouterLink> -->
                <div class="mb-8">
                  <button type="submit" class="w-full mb-6 text-black bg-white focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-[20px] text-sm px-5 py-2.5 text-center">Submit New Password</button>
  
                </div>
  
              </form>
              <div class="privacy-policy mb-8">
                <p class="text-center mb-4 font-redHat">Already have an account? <a @click="goBackToLogin" class="text-center font-bold font-redHat">Sign in.</a></p>
                <p class="text-center">By continuing, you are agree to our <br>
                  <a href="#" class="font-bold hover:text-white">Privacy Policy</a>
                </p>
              </div>
              <div class="scbd-bottom flex items-center justify-center mt-8">
                <img src="@/assets/img/mini-scbd-logo.png" alt="scbd-mini" class="w-[96px] h-[22px]">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>

import axios from "axios";
// import Cookies from "js-cookie";
import Swal from 'sweetalert2';

import { goPage, ShowLoading, CloseLoading, base_url, ErrorConnectionTimeOut, AlertPopup, AlertBottom } from "@/assets/js/function";
import Cookies from "js-cookie";

export default {

  data() {
    return {
      //   showPassword: false,
      newpassword: '',
      confirmnewpassword: '',    
      passwordError: '',
      new_pass: '',
      config: '',
      //   useremailphone: "",
      //   showAdmin: true,
      //   showOwner: false,
      //   bodyAllowNotification: "",
      //   statusAllow: "",
    };
  },
  computed: {
    buttonLabel() {
      return this.showPassword ? "Hide" : "Show";
    },
  },


  created() {
    const token = Cookies.get("token")
    this.config = {
        headers: { Authorization: `Bearer ` + token },
        timeout: 30000,
    };
    // CloseLoading();
    // this.index();
  },

  methods: {
    // toggleShow() {
    //   this.showPassword = !this.showPassword;
    // },
    // setTipeLogin(status) {
    //   ShowLoading();
    //   localStorage.setItem('tipe_login', status); goPage('dashboard');
    // },

    goBackToLogin() {
      Cookies.remove("token");
      goPage('')
    },

   


    changePassword() {
      // Check if password and confirmPassword match
      if (this.newpassword !== this.confirmnewpassword) {
        // If passwords don't match, show an error message
        AlertPopup("error", "", "Passwords do not match", 2000, false);
        return;
      }
      
      // If passwords match, assign the new_pass to the password value
      this.new_pass = this.newpassword;

      if (!this.new_pass) {
        AlertPopup("error", "", "Please enter a valid password", 2000, false);
        return;
      }

      ShowLoading();
      let formData = new FormData();
      formData.append("new_pass", this.new_pass);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'change_password';
      } else {
        this.api = base_url + 'change_password';
      }
      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          console.log(response.data);
          if (response.data.status == true) {
            AlertBottom(response.data.message);
            CloseLoading();
            goPage('');
          }

          if (response.data.status == false) {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });

    }


    // showModal(modal_name) {
    //   this.$refs[modal_name].classList.add('show');
    //   this.$refs[modal_name].style.display = 'block';
    // },
    // hideModal(modal_name) {
    //   this.$refs[modal_name].classList.remove('show');
    //   this.$refs[modal_name].style.display = 'none';

    // },

    // signUp() {

    //   window.open('https://arthaskyline.com/contact.html', "_blank");
    //   CloseLoading();

    // },

    // changePassword() {
    //   ShowLoading();
    //   let formData = new FormData();
    //   formData.append("phone", this.useremailphone);

    //   let api = "";
    //   if (process.env.NODE_ENV === "development") {
    //     api = base_url + 'send_otp';
    //   } else {
    //     api = base_url + 'send_otp';
    //   }
    //   axios
    //     .post(api, formData)
    //     .then((response) => {
    //       console.log(response.data);
    //       if (response.data.status == true) {
    //         Cookies.set("token", response.data.token);
    //         Cookies.set("useremailphone", this.useremailphone);
    //         goPage('dashboard');
    //       }

    //       if (response.data.status == false) {
    //         AlertPopup("error", "", response.data.message, 1500, false);
    //       }
    //     })
    //     .catch((error) => {

    //       ErrorConnectionTimeOut(error);
    //     });

    // }
  },


};

</script>