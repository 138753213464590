<template>
  <NavBar />
  <header class="lg:px-16 px-5 bg-white flex flex-wrap items-center py-3">
    <div class="flex-1 flex justify-between items-center">
      <div class="relative">
        <a href="javascript.void(0)" class="block text-3xl font-redHat text-[#F5CA40] font-bold">
          Rekap Sudah di beli
        </a>
        <!-- <p class="block text-xl font-redHat text-[#F5CA40] font-bold">Verifikasi Data</p> -->
      </div>
      <!-- <p class="block text-xl font-redHat text-[#F5CA40] font-bold">Story Instagram</p> -->
    </div>

    <div class="hidden md:flex md:items-center md:w-auto w-full" id="menu">
      <nav>

        <ul class="md:flex items-center justify-between text-base text-gray-700 pt-3 md:pt-0 gap-1">
          <!-- <li class="">
            <div @click="generate"
              class="font-redHat text-base  h-[40px] w-[243px] rounded-md text-white  border-2 bg-green-600 me-2 cursor-pointer">
              <div class="flex justify-center items-center h-full gap-2">
                <img src="../../../assets/img/refresh.png" alt="">
                Generate
              </div>
            </div>
          </li>
           -->
          <li v-if="this.records && this.records.length > 0">
            <div @click="convertToExcel"
              class="font-redHat text-base  h-[40px] w-[243px] rounded-md text-green-600  border-2 border-green-600 me-2 cursor-pointer">
              <div class="flex justify-center items-center h-full gap-2">
                <img src="../../../assets/img/icon_excel.png" alt="">
                Export Excel
              </div>
            </div>
          </li>
          <li>
            <div
              class="relative flex items-center p-0 rounded-[8px] w-[250px] max-w-lg h-[40px] border-[0.5px] border-[#00000]">


              <input type="text" v-model="searchQuery" @input="filterTable"
                class="rounded-[8px] p-2.5 px-6 w-full h-full focus:border-blue-500 focus:ring focus:ring-blue-200 focus:outline-none"
                name="search" placeholder="Cari">

              <button type="submit"
                class="absolute right-2 top-1/2 transform -translate-y-1/2 z-10 bg-white  p-2 rounded-full">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-5 h-5">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                </svg>
              </button>
            </div>
          </li>
          <li>
            <input type="date" v-model="date" class="flex-grow border-1 border-[#5FBAE6] rounded-md p-2"
              name="date_choose">
            <!-- <Datepicker v-model="dateRange" range="true" lang="id" class="" position="right" /> -->
          </li>

          <!-- <div class="relative p-0 rounded-[20px] w-[250px] max-w-lg"> -->
          <!-- <input type="text" class="rounded-[20px] p-3 w-full" name="search" placeholder="Cari"> -->
          <!-- </div> -->
          <!-- <li>
              <RouterLink to="addcustomer"
                class="md:p-4 py-3 block flex items-center justify-center w-[200px] h-[40px] rounded-md bg-[#50C996] text-black py-2.5 font-semibold"
              >
                Customer Baru
              </RouterLink>
            </li> -->

          <!-- <input type="date" v-model="date" class="flex-grow border-1 border-[#5FBAE6] rounded-md p-2" name="date"> -->
          <!-- <li>
            <button @click="filterDropdown = !filterDropdown" id="dropdownHoverButton"
              class="md:p-4 py-3 px-0 block flex items-center justify-center w-[100px] h-[40px] rounded-md bg-[#F5CA40] text-black px-6 py-2.5 font-semibold gap-2">
              Filter By <i class="bi bi-funnel-fill"></i>
            </button>
            <ul v-if="filterDropdown"
              class="absolute transform -translate-x-2  bg-slate-100 shadow-lg mt-2 rounded-lg w-30 space-y-2 p-1"> 
              <li
                class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 hover:rounded-lg cursor-pointer"
                :class="{ 'bg-[#5FBAE6]/80 rounded-lg': selectedType === 'STORY_IG' }"
                @click="filterRecords('STORY_IG')">
                <div>List Order</div>
              </li>
              <li
                class="block px-4 py-2 text-black font-redHat text-base hover:bg-[#5FBAE6]/80 hover:rounded-lg cursor-pointer"
                :class="{ 'bg-[#5FBAE6]/80 rounded-lg': selectedType === 'LIVE_IG' }" @click="filterRecords('LIVE_IG')">
                <div>Live Instagram</div>
              </li>
            </ul>
          </li> -->
        </ul>
      </nav>
    </div>
  </header>

  <div class="px-5 pt-5 mb-2">
    <!-- <div class="flex flex-col">
              <div class=" overflow-x-auto">
                  <div class="p-1.5 min-w-full inline-block align-middle">
                      <div class="">
                      </div>
                  </div>
              </div>
          </div> -->

    <div class="overflow-scroll" style="max-height: 400px;">
      <!-- for datatable -->
      <table id="example" class="display" style="width: 100%;">
        <thead style="position: sticky; top: 0; background-color: white; z-index: 1;">
          <tr v-if="filteredRecords.length > 0">
            <th style="width: 5%">No.</th>

            <th style="width: 20%" @click="sortColumn('supplier_name')" class="cursor-pointer d-flex">
              Supplier
              <i v-if="sortKey === 'supplier_name' && sortDirection === 'asc'" class="bi bi-arrow-up"></i>
              <i v-if="sortKey === 'supplier_name' && sortDirection === 'desc'" class="bi bi-arrow-down"></i>
            </th>

            <th style="width: 10%" @click="sortColumn('category')" class="cursor-pointer">
              Kategori
              <i v-if="sortKey === 'category' && sortDirection === 'asc'" class="bi bi-arrow-up"></i>
              <i v-if="sortKey === 'category' && sortDirection === 'desc'" class="bi bi-arrow-down"></i>
            </th>

            <th style="width: 20%" @click="sortColumn('code')" class="cursor-pointer">
              Produk
              <i v-if="sortKey === 'code' && sortDirection === 'asc'" class="bi bi-arrow-up"></i>
              <i v-if="sortKey === 'code' && sortDirection === 'desc'" class="bi bi-arrow-down"></i>
            </th>


            <th style="width: 5%" @click="sortColumn('color')" class="cursor-pointer">
              Varian
              <i v-if="sortKey === 'color' && sortDirection === 'asc'" class="bi bi-arrow-up"></i>
              <i v-if="sortKey === 'color' && sortDirection === 'desc'" class="bi bi-arrow-down"></i>
            </th>

            <th style="width: 5%" @click="sortColumn('size')" class="cursor-pointer">
              size
              <i v-if="sortKey === 'size' && sortDirection === 'asc'" class="bi bi-arrow-up"></i>
              <i v-if="sortKey === 'size' && sortDirection === 'desc'" class="bi bi-arrow-down"></i>
            </th>

            <th style="width: 5%" @click="sortColumn('qty')" class="cursor-pointer">
              Qty
              <i v-if="sortKey === 'qty' && sortDirection === 'asc'" class="bi bi-arrow-up"></i>
              <i v-if="sortKey === 'qty' && sortDirection === 'desc'" class="bi bi-arrow-down"></i>
            </th>

            <th style="width: 10%" @click="sortColumn('cost_price')" class="cursor-pointer">
              Harga Modal
              <i v-if="sortKey === 'cost_price' && sortDirection === 'asc'" class="bi bi-arrow-up"></i>
              <i v-if="sortKey === 'cost_price' && sortDirection === 'desc'" class="bi bi-arrow-down"></i>
            </th>

            <th style="width: 15%" @click="sortColumn('total')" class="text-center cursor-pointer">
              Total
              <i v-if="sortKey === 'total' && sortDirection === 'asc'" class="bi bi-arrow-up"></i>
              <i v-if="sortKey === 'total' && sortDirection === 'desc'" class="bi bi-arrow-down"></i>
            </th>
            <!-- <th style="width: 15%" class="text-center">
              Aksi
            </th> -->
          </tr>
        </thead>
        <tbody>
          <tr class="tr-table" v-for="(item, index) in filteredRecords" :key="item.id">
            <td class="custom-td-left">{{ index + 1 }}</td>
            <td class="">
              <div class="m-2">{{ item.supplier_name }}</div>
            </td>
            <td class="">
              <div class="m-2">{{ item.category }}</div>
            </td>
            <td class="">
              <div class="m-2">{{ item.code }}</div>
            </td>
            <td class="">
              <div class="m-2">{{ item.color }}</div>
            </td>
            <td class="">
              <div class="m-2">{{ item.size }}</div>
            </td>
            <td class="">
              <div class="m-2">{{ item.qty_buy }}</div>
            </td>
            <td class="">
              <div class="m-2">{{ currencyInput(item.cost_price, currency) }}</div>
            </td>
            <td class="">
              <div class="m-2">{{ currencyInput(item.total, currency) }}</div>
            </td>
            <!-- <td class="">
              <div class="m-2">
                <button type="button" @click='editData(item)'
                  class="font-redHat text-base  h-[40px] px-3 rounded-md text-white  border-2  me-2 cursor-pointer"
                  :class="{ 'bg-green-600': item.qty_buy == 0, 'bg-gray-600': item.qty_buy > 0 }"
                  :disabled="item.qty_buy > 0">
                  Terima Barang</button>
              </div>
            </td> -->
          </tr>
          <tr class="tr-table" v-if="filteredRecords.length > 0">
            <td colspan="8" class="custom-td-left fw-bold">Total</td>
            <td class="custom-td-left fw-bold text-center">{{ currencyInput(total, currency) }}</td>
          </tr>
        </tbody>
      </table>
    </div>



  </div>




  <!-- modal delete -->
  <div v-if="isModalOpen" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
    <!-- Modal content -->
    <div class="bg-white rounded-lg shadow-lg p-6 max-w-md w-full" @click.stop>
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-xl font-semibold">Delete Data Rekap</h2>
        <button @click="closeModal" class="text-red-600 hover:text-gray-900">
          ✕
        </button>
      </div>
      <p class="text-gray-700 mb-10">
        Are you sure wants to delete this data ?
      </p>

      <div class="space-y-3">
        <div aria-hidden="true" class="border-t px-2"></div>
        <div class="button-group flex justify-end">
          <button @click="closeModal" class="bg-gray-400 text-white font-bold py-2 px-4 rounded hover:bg-gray-700 me-3">
            Close
          </button>
          <button @click="delete2" class="bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-700">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>


</template>

<script>
import NavBar from '@/components/NavBar.vue'
import axios from "axios";
// import Swal from 'sweetalert2';

import { ErrorConnectionTimeOut, ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom, rupiahInput, rupiah, goPage, DateFormat, currencyInput } from "@/assets/js/function";
// import {ErrorConnectionTimeOut,  ShowLoading, CloseLoading, base_url, AlertPopup,  rupiahInput,  rupiah } from "@/assets/js/function";
import Cookies from "js-cookie";
import Multiselect from 'vue-multiselect';
import 'vue-datepicker-ui/lib/vuedatepickerui.css';
import VueDatepickerUi from 'vue-datepicker-ui';
import XLSX from "xlsx/dist/xlsx.full.min.js";

export default {
  components: {
    NavBar,
    Multiselect,
    Datepicker: VueDatepickerUi
  },

  data() {
    return {
      searchQuery: '',

      records: [
        {
          total_deposit: '',
        }
      ],
      currentData: [],
      listStatusPesanan: [],
      isModalOpen: false,
      currentDeleteIndex: null,
      config: '',
      filterDropdown: false,
      selectedType: 'LIVE_IG',
      total: 0,

      date: new Date(),

      dateRange: [
        new Date(),
        new Date()],
      currency: "",

      sortKey: '',         // Column key to sort by
      sortDirection: 'asc' // Sort direc
    }
  },

  watch: {
    date: {
      handler(newVal) {
        this.index();
      },
    }
    // dateRange: {
    //   handler(newVal) {
    //     this.index();
    //   },
    // }
  },

  created() {
    const token = Cookies.get("token");
    // console.log(token)
    this.config = {
      headers: { Authorization: `Bearer ` + token },
      timeout: 30000,
    }

    const date = new Date();
    this.date = date.toISOString().split('T')[0]; // Output: '2024-05-01'


    // console.log(this.config);
    this.index();
    this.prepareData();


    this.showMultiselect = false;

    // Load from localStorage when the component is created
    // const savedLiveIg = localStorage.getItem('records');
    // if (savedLiveIg) {
    // this.records = JSON.parse(savedLiveIg); // Load saved data into records
    // console.log('Stored records:', JSON.parse(savedLiveIg));
    // }else {
    // console.log('No records found in localStorage.');
    // }
  },

  computed: {
    filteredRecords() {
      let records = [...this.records];

      // Filter records based on search query
      if (this.searchQuery) {
        const searchLower = this.searchQuery.toLowerCase();
        records = records.filter(item => {
          return (
            (item.supplier_name ? item.supplier_name.toLowerCase().includes(searchLower) : false) ||
            (item.category ? item.category.toLowerCase().includes(searchLower) : false) ||
            (item.code ? item.code.toLowerCase().includes(searchLower) : false) ||
            (item.name ? item.name.toLowerCase().includes(searchLower) : false) ||
            (item.qty ? String(item.qty).includes(searchLower) : false) ||
            (item.cost_price ? String(item.cost_price).includes(searchLower) : false) ||
            (item.total ? String(item.total).includes(searchLower) : false)
          );
        });
      }

      // Sort records based on the selected column and direction
      if (this.sortKey) {
        records.sort((a, b) => {
          const valueA = a[this.sortKey];
          const valueB = b[this.sortKey];

          if (typeof valueA === 'string') {
            // If the values are strings, compare them alphabetically
            return this.sortDirection === 'asc'
              ? valueA.localeCompare(valueB)
              : valueB.localeCompare(valueA);
          } else {
            // If the values are numbers, compare them numerically
            return this.sortDirection === 'asc' ? valueA - valueB : valueB - valueA;
          }
        });
      }

      return records;
    }
  },


  // mounted() {
  //     this.$root.initializeDataTable(); 

  // },

  methods: {

    rupiah,
    currencyInput,
    addNumberToObjects(objArray) {
      for (let i = 0; i < objArray.length; i++) {
        const { code, supplier_name, category, color, qty_buy, size, cost_price, total, ...rest } = objArray[i];  // Extract fields to be renamed
        const newObj = {
          No: i + 1,
          "Supplier": supplier_name,  // Rename id_customer to customer_id
          "Kategori": category,  // Rename id_customer to customer_id
          "Kode Produk": code,  // Rename id_customer to customer_id
          "Varian": color,
          "Size": size,
          "Qty diterima": qty_buy,
          "Harga Modal": cost_price,
          "Total": total,
          ...rest
        };
        objArray[i] = newObj;
      }
      return objArray;
    },
    convertToExcel() {
      ShowLoading();

      // Create a deep copy of `this.records` to avoid modifying the original data
      const data = JSON.parse(JSON.stringify(this.records));
      const ArrExport = this.addNumberToObjects(data);

      for (let i = 0; i < ArrExport.length; i++) {
        delete ArrExport[i]["id"];
        delete ArrExport[i]["category"];
        delete ArrExport[i]["cost_price"];
        delete ArrExport[i]["supplier_name"];
        delete ArrExport[i]["name"];
        delete ArrExport[i]["id_supplier"];
        delete ArrExport[i]["id_product"];
        delete ArrExport[i]["id_category"];
        delete ArrExport[i]["qty_buy"];
        delete ArrExport[i]["qty"];
      }

      // Create a new workbook
      const wb = XLSX.utils.book_new();
      // Create a new worksheet
      const ws = XLSX.utils.json_to_sheet(ArrExport);
      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(wb, ws, 'REKAP');
      // Convert the workbook to an XLSX file and download it
      XLSX.writeFile(wb, 'SUDAH DI BELI' + ".xlsx");
      CloseLoading();
    },
    sortColumn(column) {
      if (this.sortKey === column) {
        // Toggle sort direction if already sorting by this column
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      } else {
        // Set sort key to new column and default direction to ascending
        this.sortKey = column;
        this.sortDirection = 'asc';
      }
    },
    nameWithLang({ name }) {
      return `${name}`;
    },
    handleOrderChange(item, index) {
      ShowLoading();
      let formData = new FormData();

      Object.entries(item).forEach(([key, value]) => {   // for looping in table
        formData.append(key, value);
      });

      // for (let [key, value] of formData.entries()) {
      //     console.log(`${key}: ${value}`);
      // }
      formData.delete('id_order_status');
      formData.append('id_order_status', item.id_order_status.id);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'live_ig_update';
      } else {
        this.api = base_url + 'live_ig_update';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          // console.log(response)
          if (response.data.status == true) {
            AlertBottom(response.data.message);

            CloseLoading();
          }
          if (response.data.status == false) {
            this.records[index].status_order = '';
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          ErrorConnectionTimeOut(error);
        });
    },
    addRow() {
      // Mark the row as "Added"
      //   this.record[index].isAdded = true

      // Add a new row with a unique id
      this.records.push({
        id_customer: '',
        id_produk: '',
        color: '',
        size: '',
        qty: '',
        price: '',
        deposit: '',
        isAdded: false
      })
    },
    getIconClass(item) {
      // Determine the icon class based on whether the row is the last one
      // return item.total_deposit > item.price ? 'bi bi-check-circle-fill text-gray-400' : 'bi bi-check-circle-fill text-[#50C996]' ;
      // console.log(item.total_deposit)
      // console.log(item.price)
      if (item.total_deposit >= item.price) {
        //return 'bi bi-check-circle-fill text-[#50C996]'; // Green verified
        return true
      }
      else {
        //return 'bi bi-check-circle-fill text-gray-400'; // Gray unverified
        return false
      }
    },
    formatCurrency(label, number, index) {
      this.records[index][label] = rupiahInput(number);
    },
    prepareData() {
      // this.records.total_deposit = rupiahInput(this.records.total_deposit);
      this.records.forEach((item) => {
        item.total_deposit = rupiahInput(item.total_deposit);
      });

    },
    editData(item) {
      // for batal edit customer page
      localStorage.setItem('currentData', JSON.stringify(item));
      goPage('accept');

    },
    editCustomer(item) {
      // for batal edit customer page
      this.$router.push({ name: 'editcustomer' });
      sessionStorage.setItem('lastPage', 'verifikasi-live-ig');

      // console.log(item)
      localStorage.setItem('currentData', JSON.stringify(item.customer_detail));

      // Navigate to the Add Customer page
      this.$router.push({ name: 'editcustomer' });
    },
    filterRecords(type) {
      this.selectedType = type; // Save the selected filter type
      this.index(); // Call the index method to fetch filtered data
    },

    index() {
      ShowLoading();
      let formData = new FormData();

      formData.append('id_preorder', localStorage.getItem('id_preorder'))
      formData.append('date', this.date);




      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'report_summary_bought';
      } else {
        this.api = base_url + 'report_summary_bought';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status === true) {
            this.records = response.data.data;
            this.total = response.data.total_result_value;
            this.currency = response.data.currency;
            localStorage.getItem('id_preorder');
            CloseLoading();
          } else {
            CloseLoading();
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          CloseLoading();
          ErrorConnectionTimeOut(error);
        });
    },
    generate() {
      ShowLoading();
      let formData = new FormData();

      formData.append('id_preorder', localStorage.getItem('id_preorder'))
      formData.append('date', this.date);
      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'generate_buy_summary';
      } else {
        this.api = base_url + 'generate_buy_summary';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          if (response.data.status === true) {
            this.records = [];
            this.total = response.data.total_result_value;
            this.currency = response.data.currency;

            this.index();

            CloseLoading();
          } else {
            CloseLoading();
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          CloseLoading();
          ErrorConnectionTimeOut(error);
        });
    },
    openModalDelete() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.id_terminate = null;
    },
    delete2() {
      ShowLoading();
      let formData = new FormData();
      formData.append("id", this.id_terminate);

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'live_ig_delete';
      } else {
        this.api = base_url + 'live_ig_delete';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {

          if (response.data.status == true) {
            const index = this.records.findIndex((item) => item.id === this.id_terminate); // Find item in the array by ID
            this.records.splice(index, 1); // Remove the item from the array
            CloseLoading();
            this.closeModal(); // Close modal after deletion
            AlertBottom(response.data.message);

          } else {
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {

          ErrorConnectionTimeOut(error);
        });


    },
    terminate(id) {
      this.id_terminate = id;
      this.openModalDelete();
      // this.tanggal_terminate = new Date();

    },


  }
}
</script>

<style scoped>
/* Adjust positioning and z-index for the multiselect dropdown */
.multiselect__content {
  position: absolute;
  z-index: 9999;
  top: 100%;
}

.multiselect-full-width .multiselect__select {
  width: 100%;
}

.no-arrows {
  -moz-appearance: textfield;
  /* Firefox */
  appearance: none;
  /* Remove default appearance */
}

.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  /* Chrome, Safari, Edge */
  margin: 0;
  /* Remove margin */
}
</style>