<template>
    <!-- <NavBar /> -->
    <div class=" justify-center mt-5 px-10">
        <div class="mb-12 flex justify-center">
            <h2 class="text-5xl font-redHat font-bold">Tambah Data Supplier</h2>
        </div>
        <form @submit.prevent="insert">
            <div class="mb-20 px-6">
                <div class="mb-4">
                    <label for="nama-toko" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Nama
                        Toko</label>
                    <input type="text" v-model="currentData.name"
                        class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="nama-toko" placeholder=""
                        required>

                </div>
                <div class="mb-4">
                    <label for="lokasipo"
                        class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Lokasi</label>
                    <!-- <input type="text" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="lokasipo" placeholder=""> -->
                    <multiselect v-model="currentData.id_location" :options="dataLokasi" :custom-label="nameWithLang"
                        placeholder="" label="name" track-by="" :selectLabel="''" :deselectLabel="''"
                        class="multiselect-full-width" disabled></multiselect>

                </div>

                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 mb-4">
                    <div class="">
                        <label for="pictoko" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">PIC
                            Toko</label>
                        <input type="text" v-model="currentData.pic"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="pictoko" placeholder="">


                    </div>
                    <div class="grid grid-cols-12">
                        <div class="col-span-12 ">
                            <label for="no-whatsapp"
                                class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">No. Telepon</label>
                            <!-- <input type="text" class=" w-16 border-1 border-[#5FBAE6] font-redHat rounded-md p-2 me-3" name="no-whatsapp" placeholder="+66"> -->
                            <div class="flex space-x-2">

                                <multiselect v-model="currentData.code_number_phone" :options="dataPhone"
                                    :custom-label="nameWithLang2" placeholder="" label="name" track-by=""
                                    :selectLabel="''" :deselectLabel="''"
                                    class="w-6 border-1 border-[#5FBAE6] font-redHat rounded-md" disabled></multiselect>
                                <input type="text" v-model="currentData.whatsapp"
                                    class="w-full border-1 border-[#5FBAE6] font-redHat rounded-md p-2"
                                    name="no-whatsapp" placeholder="">
                            </div>

                        </div>

                    </div>



                </div>




            </div>

            <div class="mb-3 space-x-5 flex justify-end px-10">
                <RouterLink to="createliveig"
                    class="bg-white text-xl font-redHat text-black text-center py-2 px-4 rounded font-bold border border-[#5FBAE6] w-[240px]">
                    Batal</RouterLink>
                <button type="submit"
                    class="bg-[#F5CA40] text-black text-xl font-redHat text-center py-2 px-4 rounded font-bold w-[240px]">Simpan</button>
            </div>
        </form>


    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import Multiselect from 'vue-multiselect';

import axios from "axios";
// import Swal from 'sweetalert2';

import { ErrorConnectionTimeOut, goPage, ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom } from "@/assets/js/function";
import Cookies from "js-cookie";

export default {
    components: {
        // NavBar,
        Multiselect,

    },

    data() {
        return {
            currentData: [],
            // value: {name: '@IgUsername'},
            // options: [
            //     {name: '@IgUsername'},
            //     {name: '@IgUsername'},
            //     {name: '@IgUsername' },
            //     {name: '@IgUsername'},
            //     {name: '@IgUsername'}
            // ],
            // newRecord: '',
            dataLokasi: [],
            dataPhone: [],
        };
    },

    created() {
        const token = Cookies.get("token");
        // console.log(token)
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 30000,
        }

        // console.log(this.config);
        // this.index();
        this.dataLokasi = JSON.parse(localStorage.getItem('data_location'));
        this.dataPhone = JSON.parse(localStorage.getItem('data_code_number'));

        const index_location = this.dataLokasi.findIndex((item) => item.name == localStorage.getItem('location'));
        this.currentData.id_location = this.dataLokasi[index_location];

        const index_phone = this.dataPhone.findIndex((item) => item.dial_code == localStorage.getItem('code_number_phone'));

        this.currentData.code_number_phone = this.dataPhone[index_phone];


    },



    methods: {
        nameWithLang({ name }) {
            return `${name}`
        },
        nameWithLang2({ dial_code }) {
            return `${dial_code}`
        },

        insert() {
            ShowLoading();
            let formData = new FormData();

            Object.entries(this.currentData).forEach(([key, value]) => {
                formData.append(key, value);
            });

            formData.delete("id_location");
            formData.append("id_location", this.currentData.id_location.id)

            formData.delete("code_number_phone");
            formData.append("code_number_phone", this.currentData.code_number_phone.dial_code)

            formData.delete("id_supplier");
            if (this.selectedSupplier && this.selectedSupplier.name) {
                formData.append("id_supplier", this.selectedSupplier.id);
            }

            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }

            // console.log(this.currentData.id_location)
            // console.log(this.currentData.code_number_phone)

            // formData.delete("foto_profile");

            // if (this.$refs.foto_profile.value != "") {

            //     formData.append("foto_profile", this.gambar_profile_64);
            // }

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'supplier_insert';
            } else {
                this.api = base_url + 'supplier_insert';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    // console.log(response)
                    if (response.data.status == true) {
                        AlertBottom(response.data.message);
                        localStorage.setItem("newSupplier", this.currentData.name);

                        goPage("createliveig");
                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
    },
}
</script>