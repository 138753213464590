<template>
  <NavBar />
  <header class="lg:px-16 px-5 bg-white flex flex-wrap items-center py-3">
    <div class="flex-1 flex justify-between items-center">
      <a href="#" class="text-3xl font-redHat text-[#F5CA40] font-bold">List Orderan</a>
    </div>

    <div class="hidden md:flex md:items-center md:w-auto w-full" id="menu">
      <nav>
        <ul class="md:flex items-center justify-between text-base text-gray-700 pt-3 md:pt-0 gap-1">
          <li>
            <RouterLink to="rekaplistpo"
              class="md:p-4 py-3 block flex items-center justify-center font-redHat text-[#F5CA40] border-1 border-[#F5CA40] w-[200px] h-[40px] rounded-md bg-black py-2.5">
              Rekapan
            </RouterLink>
          </li>
          <!-- <div class="relative p-0 rounded-[20px] w-[250px] max-w-lg"> -->
          <!-- <input type="text" class="rounded-[20px] p-3 w-full" name="search" placeholder="Cari"> -->
          <!-- </div> -->
          <li>
            <button type="button" @click="addCustomerDetail()"
              class="md:p-4 py-3 block flex items-center justify-center w-[200px] h-[40px] rounded-md bg-[#50C996] text-black py-2.5 font-semibold">
              Customer Baru
            </button>
          </li>

        </ul>
      </nav>
    </div>
  </header>

  <div class="px-5 pt-5 mb-2">
    <!-- <div class="flex flex-col">
            <div class=" overflow-x-auto">
                <div class="p-1.5 min-w-full inline-block align-middle">
                    <div class="">
                        
                        
                        
                    </div>
                </div>
            </div>
        </div> -->


    <div class="">
      <!-- for datatable -->
      <table id="example" class="display z-50">
        <thead class="" style="
              position: sticky !important;
                top: 100px !important;
              background-color: white;
              z-index: 1;
            ">
          <tr>
            <th styles="width: 100px;">No.</th>
            <th>Instagram</th>
            <th>Kode Produk</th>
            <th>Varian</th>
            <th>Size</th>
            <th>Qty</th>
            <!-- <th>Stok</th> -->
            <th>Harga Jual</th>
            <!-- <th>Deposit</th> -->
            <!-- <th>Verifikasi</th> -->
            <th>Aksi</th>
          </tr>
        </thead>
        <tbody>
          <tr class="tr-table" v-for="(item, index) in records" :key="item.id">
            <td class="custom-td-left w-[50px]">{{ index + 1 }}</td>
            <!--<td><input type="text" v-model="item.ig" /></td> -->
            <td class="w-[350px] ">
              <div class="m-2 flex justify-between align-middle gap-2">
                <div class="w-full">
                  <multiselect v-if="item.showSelectOptionCustomer" v-model="item.id_customer" :options="dataCustomer"
                    :custom-label="nameWithLangCustomer" placeholder="Pilih Akun Instagram" label="name" track-by="id"
                    :selectLabel="''" :deselectLabel="''" class="multiselect-full-width"
                    @update:modelValue="handleCustomerChange(index)"></multiselect>
                </div>
                <div class="flex align-middle">
                  <button type="button" v-if="checkingLastRow(index)" @click="addCustomer(index)"
                    class="inline-flex items-center text-lg  font-semibold text-black hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                    <i class="bi bi-plus-circle-fill"></i>
                  </button>
                </div>

                <!-- <input v-if="!item.showSelectOptionCustomer" type="text" v-model="item.instagram"
                  class="rounded h-[40px]  w-full border border-[#e8e8e8] no-arrows text-center" /> -->
                <!-- <button @click="handleCustomerSelectOption(index)"
                  class="inline-flex items-center text-lg ms-2 font-semibold disabled:opacity-50 disabled:pointer-events-none">
                  <i class="bi bi-pencil"></i>
                </button> -->
              </div>
            </td>
            <td class="w-[200px]">
              <div class="m-2">
                <multiselect v-model="item.id_product" :options="dataProduct" :custom-label="nameWithLangProduct"
                  placeholder="Pilih product" label="name" :track-by="'id'" :selectLabel="''" :deselectLabel="''"
                  class="" @open="handleProductChange(index)" @update:modelValue="afterProductChange(index)">
                </multiselect>
              </div>
            </td>
            <td class="w-[200px]">
              <div class="m-2">
                <multiselect v-model="item.color" :options="dataColor" :searchable="false" :close-on-select="true"
                  :show-labels="false" @open="handleColorChange(index)" @update:modelValue="afterColorChange(index)"
                  placeholder="Pilih Varian" :disabled="item.id_product == '' || item.id_product == undefined">
                </multiselect>

                <!-- <multiselect  v-model="item.color"
                   :options="options" placeholder="Pilih Varian"
                  label="name" track-by="" :selectLabel="''" :deselectLabel="''" class="me-2">
                </multiselect> -->
              </div>
            </td>
            <td class="w-[150px]">
              <div class="m-2">
                <multiselect v-model="item.size" :options="dataSize" :searchable="false" :close-on-select="true"
                  :show-labels="false" @open="handleSizeChange(index)" @update:modelValue="afterSizeChange(index)"
                  placeholder="Pilih Size"
                  :disabled="!item.color || item.id_product == '' || item.id_product == undefined">
                </multiselect>
              </div>
            </td>
            <td class="w-[90px] py-0">
              <div class="m-2 flex align-middle gap-2">
                <!-- <input type="number" v-model="item.qty" :max="item.max_qty" @input="handleQtyChange(index)" min="0"
                  class="rounded h-[40px]  w-full border border-[#e8e8e8] no-arrows text-center"
                  @focus="selectAll($event); focusQtyChange(index);" /> -->
                <multiselect v-model="item.qty" :options="dataQty" :searchable="false" :close-on-select="true"
                  :show-labels="false" @open="focusQtyChange(index)" @update:modelValue="handleQtyChange(index)"
                  placeholder="Pilih Qty"
                  :disabled="!item.color || item.id_product == '' || item.id_product == undefined">
                </multiselect>
                <button type="button" @click="addQty(index)"
                  class="inline-flex items-center text-lg  font-semibold text-black hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                  <i class="bi bi-plus-circle-fill"></i>
                </button>
              </div>
            </td>
            <!-- <td class="w-[160px]">
              <div class="m-2">
             
                {{ item.max_qty }}
              </div>

            </td> -->
            <td class="w-[160px]">
              <div class="m-2">
                <!-- <input type="text" v-model="item.price" class="rounded w-full  h-[40px] border border-[#e8e8e8]"
                  readonly /> -->
                {{ rupiah(item.price) }}
              </div>

            </td>
            <!-- <td class="w-[150px]">
              <div class="m-2">
                
                {{ rupiah(item.total_deposit) }}
              </div>
            </td> -->
            <!-- <td class="w-[150px]">
              <div class="m-2 ">
                <button type="button"
                  class="inline-flex items-center text-lg ms-2 font-semibold disabled:opacity-50 disabled:pointer-events-none">
                  <i
                    :class="{ 'bi bi-check-circle-fill text-[#50C996]': item.total_deposit >= item.price, 'bi bi-check-circle-fill text-gray-400': item.total_deposit < item.price, }"></i>

                </button>
              </div>
            </td> -->
            <td class="custom-td-right w-[60px]">
              <div class="flex items-center space-x-2">
                <!-- <button @click="editCustomer(item)" class="inline-flex items-center text-lg me-4 font-semibold disabled:opacity-50 disabled:pointer-events-none">
                  <i class="bi bi-pencil"></i>
                </button> -->

                <button type="button" :disabled="index == 0 && records.length == 1" @click="openModalDelete(index)"
                  class="inline-flex items-center text-lg me-4 font-semibold text-red-600 hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                  <i class="bi bi-trash"></i>
                </button>

                <button type="button" v-if="checkingLastRow(index)" @click="addRow(index)"
                  class="inline-flex items-center text-lg me-4 font-semibold text-black hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                  <i class="bi bi-plus-circle-fill"></i>
                </button>


              </div>
              <!-- <button @click="addRow" class="mt-4 p-2 bg-blue-500 text-white rounded-md">Add New Row</button> -->

              <!-- <button type="button"  @click="openModalDelete(index)" class="inline-flex items-center gap-x-2 text-lg font-semibold text-black hover:text-red-800 disabled:opacity-50 disabled:pointer-events-none">
                                        <i class="bi bi-plus-circle-fill"></i>
                                    </button> -->
            </td>
            <!-- <td>{{ item.position }}</td>
                              <td>{{ item.office }}</td>
                              <td>{{ item.age }}</td> -->
          </tr>
        </tbody>
      </table>
    </div>


  </div>


  <div class="w-full mt-4 p-4">

    <div class="fixed bottom-4 right-6">
      <button @click="selesaiLive"
        class="font-redHat text-base text-white h-[40px] w-[243px] bg-blue-500 rounded-md bg-red-600">
        Simpan
      </button>
    </div>
  </div>





  <!-- modal delete -->
  <div v-if="isModalOpen" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    style="z-index:2">
    <!-- Modal content -->
    <div class="bg-white rounded-lg shadow-lg p-6 max-w-md w-full" @click.stop>
      <div class="flex justify-between items-center mb-4">
        <h2 class="text-xl font-semibold">Delete List Order</h2>
        <button @click="closeModal" class="text-red-600 hover:text-gray-900">
          ✕
        </button>
      </div>
      <p class="text-gray-700 mb-10">
        Apakah anda yakin ingin menghapus data ini ?
      </p>

      <div class="space-y-3">
        <div aria-hidden="true" class="border-t px-2"></div>
        <div class="button-group flex justify-end">
          <button @click="closeModal" class="bg-gray-400 text-white font-bold py-2 px-4 rounded hover:bg-gray-700 me-3">
            Close
          </button>
          <button @click="confirmDelete" class="bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-700">
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Multiselect from 'vue-multiselect'
import axios from "axios";
import Swal from 'sweetalert2';

import { ErrorConnectionTimeOut, ShowLoading, CloseLoading, base_url, AlertPopup, rupiah, goPage, AlertBottom } from "@/assets/js/function";
import Cookies from "js-cookie";

export default {
  components: {
    NavBar,
    Multiselect
  },

  data() {
    return {

      records: [{
        id_customer: '',
        instagram: '',
        id_product: '',
        color: '',
        size: '',
        qty: 0,
        max_qty: 0,
        price: '',
        weight: 0,
        showSelectOptionCustomer: true,
        total_deposit: 0,
      }],
      currentData: [],
      cek_data_summary: [],
      cek_product_summary: [],
      showMultiselect: false,
      // currentData: [],
      // value: {name: '@IgUsername'},
      dataCustomer: [],
      dataProduct: [],
      dataColor: [],
      dataSize: [],
      dataQty: [],
      dataAvailable: [],
      dataRecord: [],

      isModalOpen: false,
      currentDeleteIndex: null,
      dataRecordCurrent: { id_product: 0, size: '', color: '', qty: '' },
      dataRecordCurrentColor: { id_product: 0, size: '', color: '', qty: '' },
      dataRecordCurrentQty: { id_product: 0, size: '', color: '', qty: '' },
      dataRecordCurrentSize: { id_product: 0, size: '', color: '', qty: '' },

    }
  },

  watch: {

    records: {
      handler(newval) {
        // Save records to localStorage
        localStorage.setItem('records_lo', JSON.stringify(newval));

        // this.cek_data_summary = [],
        //   this.cekItemAvailibilityArraySummary(this.dataRecord);

        // if (localStorage.getItem('records_lo') != null && localStorage.getItem('records_lo') != undefined && localStorage.getItem('records_lo') != '') {
        //   this.cekItemAvailibilityArraySummary(JSON.parse(localStorage.getItem('records_lo')));
        // }

        // this.cekProductAvailibiltyArraySummary(this.dataProduct);
      },
      deep: true // Use deep watcher since records is an array of objects

    },



  },




  created() {
    const token = Cookies.get("token");

    this.config = {
      headers: { Authorization: `Bearer ` + token },
      timeout: 30000,
    }


    this.index();



    // Load from localStorage when the component is created
    const savedLiveIg = localStorage.getItem('records_lo');
    if (savedLiveIg) {
      this.records = JSON.parse(savedLiveIg); // Load saved data into records
    } else {
      console.log('Data local not found')

    }

    //remove localstorage for link to page addcustomer
    localStorage.removeItem('history_page');
  },

  methods: {
    rupiah,


    selectAll(event) {
      // Select all text inside the input when it receives focus
      event.target.select();
    },
    addCustomerDetail() {
      localStorage.setItem('history_page', 'listorder');
      goPage('addcustomer');
    },

    addCustomer() {
      Swal.fire({
        title: "Masukan Instagram ",
        input: "text",
        inputAttributes: {
          autocapitalize: "off"
        },
        showCancelButton: true,
        confirmButtonText: "Simpan",
        showLoaderOnConfirm: true,
        confirmButtonColor: '#DC2626',
        preConfirm: async (login) => {
          let formData = new FormData();
          formData.append('id_preorder', localStorage.getItem('id_preorder'));
          formData.append('instagram', login);

          this.api = base_url + 'customer_insert';
          axios
            .post(this.api, formData, this.config)
            .then((response) => {
              ShowLoading();
              if (response.data.status === true) {
                this.dataCustomer.push({
                  id: response.data.id,
                  name: "",
                  instagram: login,
                  whatsapp: "",
                  province: "",
                  city: "",
                  district: "",
                  sub_district: "",
                  address: "",
                  type: "",
                  pos_code: "",
                  total_deposit: 0,
                  deposit: []
                })
                AlertBottom(response.data.message);
                CloseLoading();
              } else {
                CloseLoading();
                AlertPopup("error", "", response.data.message, 1500, false);
              }
            })
            .catch((error) => {
              CloseLoading();
              ErrorConnectionTimeOut(error);
            });
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        // if (result.isConfirmed) {
        //   Swal.fire({
        //     title: `${result.value.login}'s avatar`,
        //     imageUrl: result.value.avatar_url
        //   });
        // }
      });
    },
    cekItemAvailibilityArraySummary(Data) {

      if (Data == null) {
        return true;
      }

      Data.forEach(item => {
        let index = -1;


        if (typeof item.id_product != "object") {

          index = this.cek_data_summary.findIndex(item2 =>
            item2.id_product == item.id_product &&
            item2.color == item.color &&
            item2.size == item.size
          );
        } else {
          if (item.id_product == null) {
            return true;
          }
          index = this.cek_data_summary.findIndex(item2 =>
            item2.id_product == item.id_product.id &&
            item2.color == item.color &&
            item2.size == item.size
          );
        }


        if (index > -1) {
          this.cek_data_summary[index].qty = this.cek_data_summary[index].qty + item.qty;

        } else {

          if (typeof item.id_product != 'object') {
            this.cek_data_summary.push({
              id_product: item.id_product || '',
              color: item.color || '',
              size: item.size || '',
              qty: item.qty || 0
            });
          } else {

            this.cek_data_summary.push({
              id_product: item.id_product.id || '',
              color: item.color || '',
              size: item.size || '',
              qty: item.qty || 0
            });
          }

        }
      });



    },
    cekProductAvailibiltyArraySummary(Data) {

      if (Data == null) {
        return true;
      }
      Data.forEach(item => {
        let index = -1;
        if (item.product_detail == undefined) {
          return true;
        }

        item.product_detail.forEach(item_product_detail => {
          item_product_detail.sizeqty.forEach(item_size_qty => {

            index = this.cek_product_summary.findIndex(item_product_cek =>
              item_product_cek.id_product == item.id &&
              item_product_cek.color == item_product_detail.color &&
              item_product_cek.size == item_size_qty.size
            );

            if (index > -1) {
              this.cek_product_summary[index].qty = this.cek_product_summary[index].qty + item_size_qty.qty;
            } else {
              this.cek_product_summary.push({
                id_product: item.id || '',
                color: item_product_detail.color || '',
                size: item_size_qty.size || '',
                qty: item_size_qty.qty || 0
              });

            }
          })


        })




      });

    },
    deleteItemAvailibilityArraySummary(Data) {
      if (Data == null) {
        return true;
      }
      Data.forEach(item => {
        let index = -1;
        if (typeof item.id_product != "object") {
          index = this.cek_data_summary.findIndex(item2 =>
            item2.id_product == item.id_product &&
            item2.color == item.color &&
            item2.size == item.size
          );
        } else {
          index = this.cek_data_summary.findIndex(item2 =>
            item2.id_product == item.id_product.id &&
            item2.color == item.color &&
            item2.size == item.size
          );
        }


        if (index > -1) {
          this.cek_data_summary[index].qty = this.cek_data_summary[index].qty - item.qty;

        }
      });


    },
    nameWithLangCustomer({ instagram }) {
      return `${instagram}`
    },
    nameWithLangProduct({ code }) {
      return `${code}`
    },
    nameWithLangSize({ size }) {
      return `${size}`
    },
    handleCustomerChange(index) {
      const index_customer = this.dataCustomer.findIndex((record) => record.id === this.records[index].id_customer.id);
      this.records[index].total_deposit = this.dataCustomer[index_customer].total_deposit;
    },
    handleProductChange(index) {
      this.dataRecordCurrent = { id_product: 0, size: '', color: '', qty: '' };

      if (this.records[index].id_product != undefined) {
        this.dataRecordCurrent = { id_product: this.records[index].id_product.id, size: this.records[index].size, color: this.records[index].color, qty: this.records[index].qty };
      }


    },
    afterProductChange(index) {


      const index2 = this.dataProduct.findIndex((record) => record.id === this.records[index].id_product.id);


      let new_data = [];
      new_data.push(this.dataRecordCurrent);
      if (this.dataRecordCurrent.id_product > 0 && this.dataRecordCurrent.size != '' && this.dataRecordCurrent.color != '' && this.dataRecordCurrent.qty > 0) {
        this.records[index].qty = 0;
        this.records[index].size = '';
        this.deleteItemAvailibilityArraySummary(new_data);


      }
      //AVAILABLE SIZEEEE
      const productId = this.records[index].id_product.id;
      const filteredProductSummary = this.cek_product_summary.filter(item => item.id_product === productId);

      // Step 2: Adjust quantities based on `cek_data_summary`
      const adjustedSummary = filteredProductSummary.map(item => {
        // Find matching entry in cek_data_summary by color and size
        const match = this.cek_data_summary.find(data =>
          data.id_product == productId &&
          data.color == item.color &&
          data.size == item.size
        );
        // If a match is found, subtract the qty from cek_data_summary
        const adjustedQty = match ? item.qty - match.qty : item.qty;
        // Ensure the quantity doesn't go below zero
        return {
          ...item,
          qty: Math.max(0, adjustedQty)
        };
      });

      // Step 3: Filter out sizes with qty = 0 (if needed)
      const availableSizes = adjustedSummary.filter(item => item.qty > 0);

      availableSizes.forEach((item) => {
        if (item.color == this.records[index].color && item.size == this.records[index].size) {
          this.records[index].max_qty = item.qty;

        }
      });


      // if (this.records[index].qty > this.records[index].max_qty) {
      //   AlertPopup("error", "", 'Quantity Melebih stok tersedia', 2000, false);
      //   this.records[index].qty = 0;
      // }

      this.records[index].color = '';
      this.records[index].size = '';
      this.records[index].price = 0;
      this.records[index].weight = 0;




    },
    focusQtyChange(index) {
      this.records[index].qty = 0;
      if (this.records[index].id_product == null || this.records[index].size == undefined || this.records[index].size == null) {
        return true;
      }
      const index2 = this.dataProduct.findIndex((record) => record.id == this.records[index].id_product.id);
      const index3 = this.dataProduct[index2].product_detail.findIndex((record) => record.color == this.records[index].color);
      const index4 = this.dataProduct[index2].product_detail[index3].sizeqty.findIndex((record) => record.size == this.records[index].size);


      this.cek_data_summary = [];
      const record_lo = JSON.parse(localStorage.getItem('records_lo'));
      this.cekItemAvailibilityArraySummary(record_lo);


      // this.dataRecordCurrentQty = { id_product: 0, size: '', color: '', qty: '' };

      // if (this.records[index].id_product != undefined) {
      //   this.dataRecordCurrentQty = { id_product: this.records[index].id_product.id, size: this.records[index].size, color: this.records[index].color, qty: this.records[index].qty };

      // }

      // console.log(this.cek_data_summary);



      //AVAILABLE SIZEEEE
      const productId = this.records[index].id_product.id;
      const filteredProductSummary = this.cek_product_summary.filter(item => item.id_product === productId);

      // Step 2: Adjust quantities based on `cek_data_summary`
      const adjustedSummary = filteredProductSummary.map(item => {
        // Find matching entry in cek_data_summary by color and size
        const match = this.cek_data_summary.find(data =>
          data.id_product === productId &&
          data.color === item.color &&
          data.size === item.size
        );
        // If a match is found, subtract the qty from cek_data_summary
        const adjustedQty = match ? item.qty - match.qty : item.qty;
        // Ensure the quantity doesn't go below zero
        return {
          ...item,
          qty: Math.max(0, adjustedQty)
        };
      });

      // Step 3: Filter out sizes with qty = 0 (if needed)
      const availableSizes = adjustedSummary.filter(item => item.qty > 0);
      // console.log(availableSizes);

      availableSizes.forEach((item) => {
        if (item.color == this.records[index].color && item.size == this.records[index].size) {


          this.records[index].max_qty = item.qty;
          this.dataQty = [];
          for (let i = 1; i <= this.records[index].max_qty; i++) {
            this.dataQty.push(i);
          }

        }
      });



    },
    handleQtyChange(index) {

      if (this.records[index].id_product == null || this.records[index].size == undefined || this.records[index].size == null) {
        return true;
      }
      const index2 = this.dataProduct.findIndex((record) => record.id == this.records[index].id_product.id);
      const index3 = this.dataProduct[index2].product_detail.findIndex((record) => record.color == this.records[index].color);
      const index4 = this.dataProduct[index2].product_detail[index3].sizeqty.findIndex((record) => record.size == this.records[index].size);


      // let new_data = [];
      // new_data.push(this.dataRecordCurrentQty);
      // if (this.dataRecordCurrentQty.id_product > 0 && this.dataRecordCurrentQty.size != '' && this.dataRecordCurrentQty.color != '' && this.dataRecordCurrentQty.qty > 0 && this.checkingLastRow(index) == false) {
      //   this.deleteItemAvailibilityArraySummary(new_data);
      // }

      //AVAILABLE SIZEEEE
      const productId = this.records[index].id_product.id;
      const filteredProductSummary = this.cek_product_summary.filter(item => item.id_product === productId);

      // Step 2: Adjust quantities based on `cek_data_summary`
      const adjustedSummary = filteredProductSummary.map(item => {
        // Find matching entry in cek_data_summary by color and size
        const match = this.cek_data_summary.find(data =>
          data.id_product === productId &&
          data.color === item.color &&
          data.size === item.size
        );
        // If a match is found, subtract the qty from cek_data_summary
        const adjustedQty = match ? item.qty - match.qty : item.qty;
        // Ensure the quantity doesn't go below zero
        return {
          ...item,
          qty: Math.max(0, adjustedQty)
        };
      });

      // Step 3: Filter out sizes with qty = 0 (if needed)
      const availableSizes = adjustedSummary.filter(item => item.qty > 0);

      availableSizes.forEach((item) => {
        if (item.color == this.records[index].color && item.size == this.records[index].size) {

          this.records[index].max_qty = item.qty;

        }
      });





      if (this.records[index].qty > this.records[index].max_qty) {
        // AlertPopup("error", "", 'Quantity Melebih stok tersedia', 2000, false);

        const swalWithBootstrapButtons = Swal.mixin({
          customClass: {
            confirmButton: "btn btn-success",
            cancelButton: "btn btn-danger"
          },
          buttonsStyling: false
        });
        swalWithBootstrapButtons.fire({
          title: "Quantity Melebihi stok tersedia ",
          text: "apakah anda ingin menambah stok?",
          icon: "warning",
          showCancelButton: true,

          confirmButtonText: "Ya!",
          cancelButtonText: "Tidak!",
          reverseButtons: true
        }).then((result) => {
          if (result.isConfirmed) {

            Swal.fire({
              title: "Masukan Quantity yang ingin ditambahkan",
              input: "number",
              inputAttributes: {
                autocapitalize: "off"
              },
              showCancelButton: true,
              confirmButtonText: "Simpan",
              confirmButtonColor: '#DC2626',
              showLoaderOnConfirm: true,
              preConfirm: async (login) => {
                //           try {


                //           } catch (error) {
                //             Swal.showValidationMessage(`
                //   Request failed: ${error}
                // `);
                //           }

                let formData = new FormData();
                formData.append('id_preorder', localStorage.getItem('id_preorder'));

                formData.append('qty', this.dataProduct[index2].product_detail[index3].sizeqty[index4].qty + parseInt(login));
                formData.append('id_product', this.dataProduct[index2].id);
                formData.append('size', this.records[index].size);
                formData.append('color', this.records[index].color);

                this.api = base_url + 'product_update_detail';
                axios
                  .post(this.api, formData, this.config)
                  .then((response) => {
                    if (response.data.status === true) {

                      const index_sum = this.cek_product_summary.findIndex((record) =>
                        record.id_product == this.records[index].id_product.id
                        && record.color == this.records[index].color
                        && record.size == this.records[index].size
                      );

                      this.cek_product_summary[index_sum].qty = this.cek_product_summary[index_sum].qty + parseInt(login);
                      // this.afterSizeChange(index);
                      this.records[index].max_qty = this.cek_product_summary[index_sum].qty;
                      AlertBottom(response.data.message);
                      CloseLoading();
                    } else {
                      CloseLoading();
                      AlertPopup("error", "", response.data.message, 1500, false);
                    }
                  })
                  .catch((error) => {
                    CloseLoading();
                    ErrorConnectionTimeOut(error);
                  });
              },
              allowOutsideClick: () => !Swal.isLoading()
            }).then((result) => {
              // if (result.isConfirmed) {
              //   Swal.fire({
              //     title: `${result.value.login}'s avatar`,
              //     imageUrl: result.value.avatar_url
              //   });
              // }
            });


          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {

          }
        });

        this.records[index].qty = 0;
      }

      if (index4 > -1) {
        this.records[index].price = (this.dataProduct[index2].normal_price + this.dataProduct[index2].product_detail[index3].sizeqty[index4].price) * this.records[index].qty;
      } else {
        this.records[index].price = 0;
      }

      this.records[index].weight = this.dataProduct[index2].weight * this.records[index].qty;

    },
    addQty(index) {
      if (this.records[index].id_product == null || this.records[index].size == undefined || this.records[index].size == null) {
        return true;
      }
      const index2 = this.dataProduct.findIndex((record) => record.id == this.records[index].id_product.id);
      const index3 = this.dataProduct[index2].product_detail.findIndex((record) => record.color == this.records[index].color);
      const index4 = this.dataProduct[index2].product_detail[index3].sizeqty.findIndex((record) => record.size == this.records[index].size);

      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      });
      swalWithBootstrapButtons.fire({
        title: "Quantity Melebihi stok tersedia ",
        text: "apakah anda ingin menambah stok?",
        icon: "warning",
        showCancelButton: true,

        confirmButtonText: "Ya!",
        cancelButtonText: "Tidak!",
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {

          Swal.fire({
            title: "Masukan Quantity yang ingin ditambahkan",
            input: "number",
            inputAttributes: {
              autocapitalize: "off"
            },
            showCancelButton: true,
            confirmButtonText: "Simpan",
            confirmButtonColor: '#DC2626',
            showLoaderOnConfirm: true,
            preConfirm: async (login) => {
              //           try {


              //           } catch (error) {
              //             Swal.showValidationMessage(`
              //   Request failed: ${error}
              // `);
              //           }

              let formData = new FormData();
              formData.append('id_preorder', localStorage.getItem('id_preorder'));

              formData.append('qty', this.dataProduct[index2].product_detail[index3].sizeqty[index4].qty + parseInt(login));
              formData.append('id_product', this.dataProduct[index2].id);
              formData.append('size', this.records[index].size);
              formData.append('color', this.records[index].color);

              this.api = base_url + 'product_update_detail';
              axios
                .post(this.api, formData, this.config)
                .then((response) => {
                  if (response.data.status === true) {

                    const index_sum = this.cek_product_summary.findIndex((record) =>
                      record.id_product == this.records[index].id_product.id
                      && record.color == this.records[index].color
                      && record.size == this.records[index].size
                    );

                    this.cek_product_summary[index_sum].qty = this.cek_product_summary[index_sum].qty + parseInt(login);
                    // this.afterSizeChange(index);
                    this.records[index].max_qty = this.cek_product_summary[index_sum].qty;
                    AlertBottom(response.data.message);
                    CloseLoading();
                  } else {
                    CloseLoading();
                    AlertPopup("error", "", response.data.message, 1500, false);
                  }
                })
                .catch((error) => {
                  CloseLoading();
                  ErrorConnectionTimeOut(error);
                });
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            // if (result.isConfirmed) {
            //   Swal.fire({
            //     title: `${result.value.login}'s avatar`,
            //     imageUrl: result.value.avatar_url
            //   });
            // }
          });


        } else if (
          /* Read more about handling dismissals below */
          result.dismiss === Swal.DismissReason.cancel
        ) {

        }
      });
    },
    handleCustomerSelectOption(index) {
      const val = this.records[index].showSelectOptionCustomer;
      if (val == true) {
        this.records[index].showSelectOptionCustomer = false;
        this.records[index].id_customer = 0;
        this.records[index].instagram = '';
      } else {
        this.records[index].showSelectOptionCustomer = true;
        this.records[index].instagram = '';
        this.records[index].id_customer = '';
      }
    },
    handleColorChange(index) {

      this.dataRecordCurrentColor = { id_product: 0, size: '', color: '', qty: '' };

      if (this.records[index].id_product != undefined) {
        this.dataRecordCurrentColor = { id_product: this.records[index].id_product.id, size: this.records[index].size, color: this.records[index].color, qty: this.records[index].qty };
      }

      this.dataColor = []
      const index2 = this.dataProduct.findIndex((record) => record.id === this.records[index].id_product.id);

      this.dataProduct[index2].product_detail.forEach((item) => {
        this.dataColor.push(item.color);
      });

    },
    afterColorChange(index) {
      const productId = this.records[index].id_product.id;
      const filteredProductSummary = this.cek_product_summary.filter(item => item.id_product === productId);

      let new_data = [];
      new_data.push(this.dataRecordCurrentColor);
      if (this.dataRecordCurrentColor.id_product > 0 && this.dataRecordCurrentColor.size != '' && this.dataRecordCurrentColor.color != '' && this.dataRecordCurrentColor.qty > 0) {
        this.deleteItemAvailibilityArraySummary(new_data);
      }

      // Step 2: Adjust quantities based on `cek_data_summary`
      const adjustedSummary = filteredProductSummary.map(item => {
        // Find matching entry in cek_data_summary by color and size
        const match = this.cek_data_summary.find(data =>
          data.id_product === productId &&
          data.color === item.color &&
          data.size === item.size
        );

        // If a match is found, subtract the qty from cek_data_summary
        const adjustedQty = match ? item.qty - match.qty : item.qty;

        // Ensure the quantity doesn't go below zero
        return {
          ...item,
          qty: Math.max(0, adjustedQty)
        };
      });

      // Step 3: Filter out sizes with qty = 0 (if needed)
      const availableSizes = adjustedSummary.filter(item => item.qty > 0);
      this.dataSize = [];
      // this.dataQty = [];
      this.records[index].size = '';
      this.records[index].qty = 0;
      this.records[index].max_qty = 0;

      availableSizes.forEach((item) => {
        if (item.color == this.records[index].color) {

          this.dataSize.push(item.size);
          // for (let i = 1; i <= item.qty; i++) {
          //   this.dataQty.push(i);
          // }
        }
      });


      // this.dataAvailable = availableSizes;




    },
    handleSizeChange(index) {

      const productId = this.records[index].id_product.id;
      const filteredProductSummary = this.cek_product_summary.filter(item => item.id_product === productId);

      this.dataRecordCurrentSize = { id_product: 0, size: '', color: '', qty: '' };

      if (this.records[index].id_product != undefined) {
        this.dataRecordCurrentSize = { id_product: this.records[index].id_product.id, size: this.records[index].size, color: this.records[index].color, qty: this.records[index].qty };
      }

      // Step 2: Adjust quantities based on `cek_data_summary`
      const adjustedSummary = filteredProductSummary.map(item => {
        // Find matching entry in cek_data_summary by color and size
        const match = this.cek_data_summary.find(data =>
          data.id_product === productId &&
          data.color === item.color &&
          data.size === item.size
        );

        // If a match is found, subtract the qty from cek_data_summary
        const adjustedQty = match ? item.qty - match.qty : item.qty;

        // Ensure the quantity doesn't go below zero
        return {
          ...item,
          qty: Math.max(0, adjustedQty)
        };
      });

      // Step 3: Filter out sizes with qty = 0 (if needed)
      const availableSizes = adjustedSummary.filter(item => item.qty > 0);
      this.dataSize = [];

      availableSizes.forEach((item) => {
        if (item.color == this.records[index].color) {
          this.dataSize.push(item.size);
        }
      });

      // this.dataAvailable = availableSizes;


      //cek product size 

      // cek index

      // this.records[index].max_qty = this.dataAvailable[0];

      // this.records[index].max_qty = ;
      // this.dataSize = []
      // const index2 = this.dataProduct.findIndex((record) => record.id === this.records[index].id_product.id);
      // const index3 = this.dataProduct[index2].product_detail.findIndex((record) => record.color === this.records[index].color);
      // this.dataProduct[index2].product_detail[index3].sizeqty.forEach((item) => {
      //   this.dataSize.push(item.size);
      // });

    },

    afterSizeChange(index) {
      const productId = this.records[index].id_product.id;
      const filteredProductSummary = this.cek_product_summary.filter(item => item.id_product === productId);

      let new_data = [];
      new_data.push(this.dataRecordCurrentSize);
      if (this.dataRecordCurrentSize.id_product > 0 && this.dataRecordCurrentSize.size != '' && this.dataRecordCurrentSize.color != '' && this.dataRecordCurrentSize.qty > 0) {
        this.deleteItemAvailibilityArraySummary(new_data);
      }

      // Step 2: Adjust quantities based on `cek_data_summary`
      const adjustedSummary = filteredProductSummary.map(item => {
        // Find matching entry in cek_data_summary by color and size
        const match = this.cek_data_summary.find(data =>
          data.id_product === productId &&
          data.color === item.color &&
          data.size === item.size
        );

        // If a match is found, subtract the qty from cek_data_summary
        const adjustedQty = match ? item.qty - match.qty : item.qty;

        // Ensure the quantity doesn't go below zero
        return {
          ...item,
          qty: Math.max(0, adjustedQty)
        };
      });

      // Step 3: Filter out sizes with qty = 0 (if needed)
      const availableSizes = adjustedSummary.filter(item => item.qty > 0);
      this.dataQty = [];
      availableSizes.forEach((item) => {
        if (item.color == this.records[index].color && item.size == this.records[index].size) {
          this.records[index].max_qty = item.qty;
          this.records[index].qty = 0;

          for (let i = 1; i <= item.qty; i++) {
            this.dataQty.push(i);
          }

        }
      });
      // this.dataAvailable = availableSizes;
      // const index_product_available = this.dataAvailable.findIndex((record) => record.id_product === this.records[index].id_product.id && record.size === this.records[index].size);
      // this.records[index].max_qty = this.dataAvailable[index_product_available].qty;


      if (this.records[index].size != undefined) {
        const index2 = this.dataProduct.findIndex((record) => record.id == this.records[index].id_product.id);
        const index3 = this.dataProduct[index2].product_detail.findIndex((record) => record.color == this.records[index].color);
        const index4 = this.dataProduct[index2].product_detail[index3].sizeqty.findIndex((record) => record.size == this.records[index].size);
        this.records[index].price = (this.dataProduct[index2].normal_price + this.dataProduct[index2].product_detail[index3].sizeqty[index4].price) * this.records[index].qty;
      }





    },

    addRow(index) {

      // Add a new row with a unique id
      this.records.push({
        id_customer: '',
        id_product: '',
        instagram: '',
        color: '',
        size: '',
        qty: 0,
        max_qty: 0,
        price: '',
        weight: 0,
        dataColor: [],  // Initialize colors array
        dataSize: [],
        showSelectOptionCustomer: true,
        total_deposit: 0,
      })
      let new_data = [];

      new_data.push(this.records[index]);
      this.cekItemAvailibilityArraySummary(new_data);
    },

    checkingLastRow(index) {
      if (index == this.records.length - 1) {
        // this.records[index].isAdded = true;
        return true;

        // this.records.splice(index + 1, 0, );
      } else {
        // this.records[index].isAdded = false;
        return false;

      }
    },

    editCustomer(item) {
      // for batal edit customer page
      // this.$router.push({ name: 'editcustomer' });


      const index = this.dataCustomer.findIndex(customer => customer.id === item.id_customer.id);


      localStorage.setItem('currentData', JSON.stringify(this.dataCustomer[index]));
      goPage('editcustomer')
      sessionStorage.setItem('lastPage', 'list-order');


      // localStorage.setItem('currentData', JSON.stringify(item.customer_detail));

      // Navigate to the Add Customer page
      // this.$router.push({ name: 'editcustomer' });
    },


    index() {
      ShowLoading();
      let formData = new FormData();

      formData.append('id_preorder', localStorage.getItem('id_preorder'))
      formData.append('type', 'STORY_IG')
      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'live_ig';
      } else {
        this.api = base_url + 'live_ig';
      }

      axios
        .post(this.api, formData, this.config)
        .then((response) => {
          console.log(response.data);
          if (response.data.status === true) {

            this.dataProduct = response.data.product;
            this.dataCustomer = response.data.customer; //to fetch customer
            this.dataProduct = response.data.product; //to fetch product


            this.cek_data_summary = [];
            this.cekItemAvailibilityArraySummary(response.data.data);//data penjualan 
            this.dataRecord = response.data.data;

            if (localStorage.getItem('records_lo') != null && localStorage.getItem('records_lo') != undefined && localStorage.getItem('records_lo') != '') {
              this.cekItemAvailibilityArraySummary(JSON.parse(localStorage.getItem('records_lo')));
            }

            this.cekProductAvailibiltyArraySummary(response.data.product);



            CloseLoading();
          } else {
            CloseLoading();
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          CloseLoading();
          ErrorConnectionTimeOut(error);
        });
    },

    selesaiLive() {
      ShowLoading();
      let formData = new FormData();

      formData.append('id_preorder', localStorage.getItem('id_preorder'));
      formData.append('type', 'STORY_IG');

      if (process.env.NODE_ENV === "development") {
        this.api = base_url + 'story_ig_insert';
      } else {
        this.api = base_url + 'story_ig_insert';
      }
      let data = [];

      this.records.forEach((item) => {
        let id_product;
        let id_customer;

        if (typeof item.id_customer === 'object') {
          id_customer = item.id_customer.id;
        } else {
          id_customer = 0;
        }
        if (typeof item.id_product === 'object') {
          id_product = item.id_product.id;
        }

        let item_prepare = { ...item, id_product: id_product, id_customer: id_customer };
        delete item_prepare.dataColor;
        delete item_prepare.dataSize;

        if (item.id_customer == 0 && item.instagram == '') {
        } else {
          data.push(item_prepare);
        }

        // data.push(item_prepare);
      });
      formData.append('data', JSON.stringify(data));




      axios
        .post(this.api, formData, this.config)
        .then((response) => {

          if (response.data.status === true) {
            localStorage.removeItem('records_lo');
            goPage('dashboard');
            AlertBottom(response.data.message);
            CloseLoading();
          } else {
            CloseLoading();
            AlertPopup("error", "", response.data.message, 1500, false);
          }
        })
        .catch((error) => {
          CloseLoading();
          ErrorConnectionTimeOut(error);
        });


    },

    openModalDelete(index) {
      this.currentDeleteIndex = index;

      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    confirmDelete() {
      // Handle the actual deletion here
      if (this.currentDeleteIndex !== null) {


        // cek quantity before delete
        let new_data = [];
        new_data.push(this.records[this.currentDeleteIndex]);
        this.deleteItemAvailibilityArraySummary(new_data);

        // Delete the item
        this.records.splice(this.currentDeleteIndex, 1);

        this.closeModal();  // Close the modal after deletion
      }
    },
  }

}
</script>

<style scoped>
/* Adjust positioning and z-index for the multiselect dropdown */
.multiselect__content {
  position: absolute;
  z-index: 9999;
  top: 100%;
}

.multiselect-full-width .multiselect__select {
  width: 100%;
}

.swal2-title {
  font-size: 1.875rem !important;
}

/* .swal2-confirm .swal2-styled {
  background-color: rgb(220 38 38 / 1) !important;
} */

.no-arrows {
  -moz-appearance: textfield;
  /* Firefox */
  appearance: none;
  /* Remove default appearance */
}

.no-arrows::-webkit-outer-spin-button,
.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  /* Chrome, Safari, Edge */
  margin: 0;
  /* Remove margin */
}
</style>