<template>
    
  
  
      <div class="bg-gray-500 h-screen w-full flex items-center justify-center py-8">
          <div class="max-w-lg w-[700px] rounded-lg bg-[#5FBAE6] h-[580px] flex flex-col items-center p-8">
              <!-- Heading -->
              <div class="block mb-14 mt-6">
                  <h5 class="font-redHat text-3xl font-medium text-[#F5CA40F2]">PO Batch Baru</h5>
              </div>
  
              <!-- Form -->
               <form action="" @submit.prevent="insert">
                   <div class="block mb-10 w-full">
                       <form class="w-full max-w-lg mx-auto">
                           <label for="countries" class="block mb-2 text-lg font-redHat font-medium text-gray-900 dark:text-white">PO ke</label>
                           <input type="text"  class="bg-gray-50 border border-gray-300 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 font-redHat font-medium text-[#5FBAE6]" placeholder="Auto" disabled>
                       </form>
                   </div>
                   <div class="block mb-10 w-full">
                       <form class="w-full max-w-lg mx-auto">
                            <div class="flex items-center justify-between">
                                <label class=" mb-2 text-lg font-redHat font-medium text-gray-900">Lokasi PO</label>
                                <button @click="addLocation" >
                                    <i class='bx bx-plus-circle text-xl' ></i>
                                    
                                </button>
                                
                            </div>
                            <multiselect
                                     v-model="selectedLocation"
                                     :options="dataLokasi"
                                     :custom-label="nameWithLang"
                                     placeholder=""
                                     label="name"
                                     track-by="name"
                                     :selectLabel="''"
                                     :deselectLabel="''"
                                     class="bg-gray-50 border border-gray-300 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full font-redHat font-medium text-[#5FBAE6]"
                             ></multiselect>
                       </form>
                   </div>
                   <div class="block mb-9 w-full ">
                     <label class="block mb-2 text-lg font-redHat font-medium text-gray-900">Periode</label>
                     <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-2">
                         <div class="relative flex items-center text-[#5FBAE6]">
                             <input
                             type="date"
                             v-model="currentData.start_date"
                             class="border border-gray-300 rounded-lg pl-3 pr-10 py-2 w-full focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                             />
                             
                         </div>
                         <div class="relative flex items-center text-[#5FBAE6]">
                             <input
                             type="date"
                             v-model="currentData.end_date"  :min="currentData.start_date"
                             class="border border-gray-300 rounded-lg pl-3 pr-10 py-2 w-full focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
                             />
                             
                         </div>
                     </div>
     
                   </div>
                   <!-- Submit Button -->
                   <div class="block">
                       <button type="submit" class="text-black font-redHat font-bold text-lg flex items-center justify-center px-5 py-2 bg-[#F5CA40] rounded-md w-[450px]">Simpan</button>
                   </div>
               </form>

  
          </div>
      </div>
  
  </template>
  
  <script>
  // import  from 'vue-select'
    import axios from "axios";
    // import Swal from 'sweetalert2';
    import Multiselect from 'vue-multiselect';

    import {ErrorConnectionTimeOut, goPage, ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom } from "@/assets/js/function";
    // import {ShowLoading, CloseLoading } from "@/assets/js/function";
    import Cookies from "js-cookie";
  
    export default {
        components: {
            // 'v-select': vSelect,
            Multiselect,

        },
    
        data() {
            return {
                currentData: [
                    {   
                        // id_location: {
                        //     id: null
                        // },
                        start_date: null,
                        end_date: null
                    }
                ],
                dataLokasi: [],
                selectedLocation: null,
                newlyAddedLocation: null
                // currentData: {
                //     id_location: null, // This will store the selected location ID
                // },
                // selectedLocationId: null,
                // currency: '',
                // newRecord: '',
            };
        },

        created() {
            const token = Cookies.get("token");
            // console.log(token)
            this.config = {
                headers: { Authorization: `Bearer ` + token },
                timeout: 30000,
            }


            const today = new Date().toISOString().split('T')[0];
            this.currentData.start_date = today;
            this.currentData.end_date = today; 

            

            
            //get data
            this.index();
            

            // this.dataLokasi = JSON.parse(localStorage.getItem('data_location'));

            

            

            // console.log('Index of the new location:', index);

            
            // setelah dpt maka bakal masukin data lokasi dengan index yg ditemukan ke dlm id_location
            // this.currentData.id_location = this.dataLokasi[index];
            // console.log(this.currentData.id_location);


            // console.log(this.config);
            
            
             
            // this.dataLokasi = JSON.parse(localStorage.getItem('data_location'));

            // console.log(this.dataLokasi);
            
            

        },
        watch: {
            // Watch for changes in start_date and update end_date accordingly
            'currentData.start_date'(newStartDate) {
                if (newStartDate > this.currentData.end_date) {
                    this.currentData.end_date = newStartDate;
                }
            },

            // selectedLocation(newVal) {
            //     localStorage.setItem('newLocation', JSON.stringify(newVal));
            // },
        

            
        },

        

        methods: {
            nameWithLang ({name}) {
                return `${name}`
            },

            

            addLocation() {
                // for batal edit customer page
                goPage('addbatchlocation')
                // sessionStorage.setItem('previousPage', 'create-po-batch');
                
                
                
            },



            index() {
                ShowLoading();

                if (process.env.NODE_ENV === "development") {
                    this.api = base_url + 'preorder';
                } else {
                    this.api = base_url + 'preorder';
                }

                axios
                    .post(this.api, '', this.config)
                    .then((response) => {
                        // console.log(response.data);
                        if (response.data.status === true) {
                            // this.record = response.data.data
                            this.dataLokasi = response.data.data_location

                            localStorage.setItem('data_code_number', JSON.stringify(response.data.data_code_number));
                        
                           

                            if(localStorage.getItem('newLocation') != undefined || localStorage.getItem('newLocation') != null){
                                const index = this.dataLokasi.findIndex((item) => item.name == localStorage.getItem('newLocation') );

                                this.selectedLocation = this.dataLokasi[index];
                            }
            
            

                            CloseLoading();
                        } else {
                            CloseLoading();
                            AlertPopup("error", "", response.data.message, 1500, false);
                        }
                    })
                    .catch((error) => {
                        CloseLoading();
                        ErrorConnectionTimeOut(error);
                    });
            },
        

            insert() {
                ShowLoading();
                let formData = new FormData();

                Object.entries(this.currentData).forEach(([key, value]) => {
                    formData.append(key, value);
                });

                formData.delete("id_location");
                if (this.selectedLocation && this.selectedLocation.name) {
                    formData.append("id_location", this.selectedLocation.id);
                }
                // formData.append("id_location", this.currentData.id_location.id)

                // for (let [key, value] of formData.entries()) {
                //     console.log(`${key}: ${value}`);
                // }
                // CloseLoading();
                // return true
                
                // console.log(this.currentData.id_location)
                // console.log(this.currentData.code_number_phone)


                if (process.env.NODE_ENV === "development") {
                    this.api = base_url + 'preorder_insert';
                } else {
                    this.api = base_url + 'preorder_insert';
                }

                axios
                    .post(this.api, formData, this.config)
                    .then((response) => {
                        console.log(response)
                        if (response.data.status == true) {
                            AlertBottom(response.data.message);
                            goPage("dashboard");
                            CloseLoading();
                        }
                        if (response.data.status == false) {
                            AlertPopup("error", "", response.data.message, 1500, false);
                        }
                    })
                    .catch((error) => {
                        ErrorConnectionTimeOut(error);
                    });
            },
        },
    
    };
  </script>
  