<template>
    <NavBar />
    <div class=" justify-center mt-5 px-10">
        <div class="mb-12 flex justify-center">
            <h2 class="text-5xl font-redHat font-bold">Edit Lokasi Baru</h2>
        </div>
        <form @submit.prevent="insert">
            <div class="mb-20 px-6">
                <div class="mb-4">
                    <label for="lokasi" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Lokasi
                        PO</label>
                    <input type="text" v-model="currentData.name"
                        class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="lokasi" placeholder="">

                </div>

                <div class="mb-4">
                    <label for="Mata-uang" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Mata
                        Uang</label>
                    <multiselect v-model="currentData.currency" :options="options" :custom-label="nameWithLang"
                        placeholder="" label="name" track-by="" :selectLabel="''" :deselectLabel="''"
                        class="bg-gray-50 border border-gray-300 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full font-redHat font-medium text-[#5FBAE6]">
                    </multiselect>
                    <!-- <input type="text" v-model="currentData.currency" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="mata-uang" placeholder=""> -->

                </div>

                <div class="">
                    <label for="Mata-uang" class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kode
                        Telepon Negara</label>
                    <!-- <input type="text" v-model="currentData.currency" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="mata-uang" placeholder="" required> -->
                    <multiselect v-model="currentData.code_number_phone" :options="dataCodeNumber"
                        :custom-label="dialWithLang" placeholder="" label="name" track-by="" :selectLabel="''"
                        :deselectLabel="''"
                        class="bg-gray-50 border border-gray-300 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full font-redHat font-medium text-[#5FBAE6]">
                    </multiselect>

                </div>




            </div>

            <div class="mb-3 space-x-5 flex justify-end px-10">
                <RouterLink to="lokasipo"
                    class="bg-white text-xl font-redHat text-black text-center py-2 px-4 rounded font-bold border border-[#5FBAE6] w-[240px]">
                    Batal</RouterLink>
                <button type="submit"
                    class="bg-[#F5CA40] text-black text-xl font-redHat text-center py-2 px-4 rounded font-bold w-[240px]">Simpan</button>
            </div>
        </form>


    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import axios from 'axios';
import { ErrorConnectionTimeOut, goPage, ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom } from "@/assets/js/function";
import Cookies from "js-cookie";
import Multiselect from 'vue-multiselect'



export default {
    components: {
        NavBar,
        Multiselect

    },

    data() {
        return {
            currentData: [
                // {
                //     currency: null,

                // }
            ],
            options: [

                { name: '฿ (THB)' },
                { name: '¥ (CNY)' },
                { name: '₩ (KRW)' },
                { name: '$ (USD)' },
            ],
            dataCodeNumber: [],

            // newRecord: '',
        };
    },

    created() {
        // this.newRecord = JSON.parse(localStorage.getItem('record')) || [];
        const token = Cookies.get("token");
        this.config = {
            headers: {
                Authorization: `Bearer ` + token,
            },
            timeout: 30000,
        }

        //get data
        this.currentData = JSON.parse(localStorage.getItem('currentData'));
        this.dataCodeNumber = JSON.parse(localStorage.getItem('data_code_number'));

        const index = this.options.findIndex((item) => item.name == this.currentData.currency);
        this.currentData.currency = this.options[index];

        const index_number = this.dataCodeNumber.findIndex((item) => item.dial_code == this.currentData.code_number_phone);

        this.currentData.code_number_phone = this.dataCodeNumber[index_number];
    },

    methods: {
        nameWithLang({ name }) {
            return `${name}`
        },
        dialWithLang({ dial_code }) {
            return `${dial_code}`
        },

        insert() {
            ShowLoading();
            let formData = new FormData();



            // if (this.currentData.currency) {
            //     formData.delete('currency');
            //     formData.append('currency', this.currentData.currency.name); // Use the name or appropriate property
            // }


            Object.entries(this.currentData).forEach(([key, value]) => {   // for looping in table
                formData.append(key, value);
            });

            formData.delete('currency');
            formData.append('currency', this.currentData.currency.name)
            formData.delete('code_number_phone');
            formData.append('code_number_phone', this.currentData.code_number_phone.dial_code)


            // for (let [key, value] of formData.entries()) {
            //     console.log(`${key}: ${value}`);
            // }






            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'location_update';
            } else {
                this.api = base_url + 'location_update';
            }


            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    // console.log(response.data)
                    if (response.data.status == true) {
                        AlertBottom(response.data.message);
                        goPage("lokasipo");
                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
    },
}
</script>