<template>
    <NavBar />
    <div class=" justify-center mt-5 px-10">
        <div class="mb-12 flex justify-center">
            <h2 class="text-5xl font-redHat font-bold">Customer Baru</h2>
        </div>
        <form @submit.prevent="insert">
            <div class="mb-20 px-6 mx-3">
                <div class="grid grid-cols-3 gap-4">
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Instagram</label>
                        <input type="text" v-model="currentData.instagram"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="instagram"
                            placeholder="" required>

                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Nama Customer</label>
                        <input type="text" v-model="currentData.name"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="name" placeholder=""
                            required>

                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">No. Whatsapp</label>
                        <input type="text" v-model="currentData.whatsapp"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="whatsapp" placeholder=""
                            required>

                    </div>

                </div>

                <!-- tipe customer -->
                <div class="button-group grid grid-cols-2 mt-5 gap-4">
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Tipe Customer</label>
                        <button type="button" class="w-full text-center items-center border-1 rounded py-2"
                            :class="{ 'bg-[#F5CA40] text-white': selectedButton === 'Reguler' }"
                            @click="selectButton('Reguler')">Reguler</button>


                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2"></label>
                        <button type="button" class="mt-4 w-full text-center items-center border-1 rounded py-2"
                            :class="{ 'bg-[#F5CA40] text-white': selectedButton === 'Reseller' }"
                            @click="selectButton('Reseller')">Reseller</button>


                    </div>

                </div>

                <div class="bg-[#F5F5F5] rounded-[8px] grid grid-cols-1 gap-1 px-4 py-4 mt-5 relative">



                    <div class="relative" v-for="(item, index) in currentData.address" :key="index">

                        <div class="grid grid-cols-1 mt-3">
                            <button type="button" v-if="checkingLastRow(index)" @click="addRowAlamat"
                                class="absolute top-5 right-5 text-[#5FBAE6F2] text-[24px]"><i
                                    class="bi bi-plus-circle"></i></button>
                            <button type="button" v-if="!checkingLastRow(index)" @click="removeDeposit(index)"
                                class=" absolute text-red-500 top-5 right-5 text-[24px]">
                                <i class="bi bi-trash"></i>
                            </button>
                        </div>

                        <!-- Address Section -->
                        <div class="grid grid-cols-12 mt-3">
                            <div class="col-span-12">
                                <label for="alamat"
                                    class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Alamat</label>
                                <textarea name="address" rows="4"
                                    class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" v-model="item.address"
                                    id="address"></textarea>
                            </div>
                        </div>



                        <!-- Province, City, etc. -->
                        <div class="grid grid-cols-4 gap-4 mt-5">
                            <div>
                                <label
                                    class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Provinsi</label>
                                <multiselect v-model="item.province" :options="provinsiList" label="text" track-by="id"
                                    placeholder="Pilih Provinsi" class="multiselect-full-width" :selectLabel="''"
                                    :deselectLabel="''" @update:modelValue="handleProvince(index)"></multiselect>
                            </div>

                            <div>
                                <label
                                    class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kota/Kabupaten</label>
                                <multiselect v-model="item.city" :options="kabupatenList"
                                    placeholder="Pilih Kota/Kabupaten" label="text" track-by="id"
                                    class="multiselect-full-width" :selectLabel="''" :deselectLabel="''"
                                    @update:modelValue="handleCity(index)" @click="handleProvince(index)"></multiselect>
                            </div>

                            <div>
                                <label
                                    class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kecamatan</label>
                                <multiselect v-model="item.district" :options="kecamatanList"
                                    placeholder="Pilih Kecamatan" label="text" track-by="id"
                                    class="multiselect-full-width" :selectLabel="''" :deselectLabel="''"
                                    @update:modelValue="handleDistrict(index)" @click="handleCity(index)"></multiselect>
                            </div>

                            <div>
                                <label
                                    class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kelurahan</label>
                                <multiselect v-model="item.sub_district" :options="kelurahanList"
                                    placeholder="Pilih Kelurahan" label="text" track-by="id"
                                    class="multiselect-full-width" :selectLabel="''" :deselectLabel="''"
                                    @update:modelValue="handleSub_district(index)" @click="handleDistrict(index)">
                                </multiselect>
                            </div>

                            <div>
                                <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kode
                                    Pos</label>
                                <input type="text" v-model="item.pos_code"
                                    class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="">
                            </div>
                        </div>
                    </div>

                </div>




                <!-- <div class="grid grid-cols-12 mt-5">
                    <div class="col-span-12">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Alamat</label>
                        <textarea name="address" rows="4" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                            v-model="currentData.address" id="address"></textarea>

                    </div>
                </div> -->

                <!-- provinsi section -->
                <!-- <div class="grid grid-cols-4 gap-4 mt-5">
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Provinsi</label>
                        <multiselect v-model="currentData.province" :options="provinsiList" label="text" track-by="id"
                            placeholder="Pilih Provinsi" :selectLabel="''" :deselectLabel="''"
                            class="multiselect-full-width"></multiselect>

                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kota/Kabupaten</label>
                        
                        <multiselect v-model="currentData.city" :options="kabupatenList"
                            placeholder="Pilih Kota/Kabupaten" label="text" track-by="id" :selectLabel="''"
                            :deselectLabel="''" class="multiselect-full-width"></multiselect>

                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kecamatan</label>
                        
                        <multiselect v-model="currentData.district" :options="kecamatanList"
                            placeholder="Pilih kecamatan" label="text" track-by="id" :selectLabel="''"
                            :deselectLabel="''" class="multiselect-full-width"></multiselect>

                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kelurahan</label>
                        
                        <multiselect v-model="currentData.sub_district" :options="kelurahanList"
                            placeholder="Pilih Kelurahan" label="text" track-by="id" :selectLabel="''"
                            :deselectLabel="''" class="multiselect-full-width"></multiselect>

                    </div>

                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kode Pos</label>
                        <input type="text" v-model="currentData.pos_code"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="alamat" placeholder="">

                    </div>
                </div> -->

                <!-- deposit -->
                <!-- <div class="bg-[#F5F5F5] rounded-[8px] grid grid-cols-1 gap-1 px-4 py-4 mt-5">
                    <div class="">
                        <label class=" font-redHat text-[#8F8F8F] font-medium font-2xl mb-3">Deposit</label>
                        <div class="flex gap-3 w-full mb-2" v-for="(item, index) in currentData.deposit" :key="index">

                            <input type="date" v-model="item.date" class="flex-grow border-1 border-[#5FBAE6] rounded-md p-2" name="date">
                            <input type="text" v-model="item.name" class="flex-grow border-1 border-[#5FBAE6] rounded-md p-2" name="atas_nama" placeholder="Atas Nama">
                            <input type="text" v-model="item.bank_name" class="flex-grow border-1 border-[#5FBAE6] rounded-md p-2" name="bank" placeholder="Nama Bank">
                            <input type="text" v-model="item.amount" @input="formatCurrency('amount', item.amount,index)" class="flex-grow border-1 border-[#5FBAE6] rounded-md p-2" name="jumlah" placeholder="Jumlah">
                            <button type="button" v-if="!checkingLastRow(index)" @click="removeDeposit(index)" class="text-red-500 text-[24px]">
                                <i class="bi bi-trash"></i>
                            </button>
                            <button type="button" v-if="checkingLastRow(index)" @click="addDeposit" class="text-[#5FBAE6F2] text-[24px]"><i class="bi bi-plus-circle"></i></button>
                        
                            
                        </div>
                            
                    </div>
                    
                </div> -->










            </div>
            <div class="mb-3 space-x-5 flex justify-end px-10 ">
                <button type="button" @click="back()"
                    class="bg-white text-xl font-redHat text-black text-center py-2 px-4 rounded font-bold border border-[#5FBAE6] w-[240px]">
                    Batal</button>
                <button type="submit"
                    class="bg-[#F5CA40] text-black text-xl font-redHat text-center py-2 px-4 rounded font-bold w-[240px]">Simpan</button>
            </div>
        </form>


    </div>

</template>

<style scoped>
.selected {
    background-color: #F5CA40;
    color: white;
}
</style>



<script>
import NavBar from '@/components/NavBar.vue';
import axios from 'axios';
import { ErrorConnectionTimeOut, goPage, ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom, base_url_wilayah_indonesia } from "@/assets/js/function";
// import {ErrorConnectionTimeOut, ShowLoading, CloseLoading, base_url_wilayah_indonesia} from "@/assets/js/function";
import Cookies from "js-cookie";
import Multiselect from 'vue-multiselect';
import { setBlockTracking } from 'vue';


export default {
    components: {
        NavBar,
        Multiselect,

    },

    data() {
        return {
            // currentData: [],
            currentData: {

                // deposit: [
                //     { date: '', name: '', bank_name: '', amount: '' }
                // ],
                address: [
                    {
                        address: '',
                        province: {},
                        city: {},
                        district: {},
                        sub_district: {},
                        pos_code: '',

                    },
                    // {
                    //     address: '',

                    //     province: null,
                    //     city: null,
                    //     district: null,
                    //     sub_district: null,
                    //     pos_code: ''
                    // }
                ],
                // province: {},
                // city: {},
                // district: {},
                // sub_district: {},
                type: 'Reguler',
            },
            selectedButton: 'Reguler',
            // dataDeposit: [],
            // newRecord: '',
            selectedButton: null,
            isModalOpen: false,
            currentDeleteIndex: null,
            // for alamat
            provinsiList: [],
            kabupatenList: [],
            kecamatanList: [],
            kelurahanList: [],



        };
    },

    created() {
        const token = Cookies.get("token");
        // console.log(token)
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 30000,
        }

        if (!this.currentData.type) {
            this.currentData.type = 'Reguler'; // Set default type
            this.selectedButton = 'Reguler'; // Set default button
        } else {
            this.selectedButton = this.currentData.type; // Set button based on existing type
        }

        // console.log(this.config);
        // this.index();
        // this.dataDeposit = JSON.parse(localStorage.getItem('data_deposit'));
        this.provinsi_index();

    },

    // watch: {
    //     'item.province': {
    //         handler(newValue) {
    //             const index = this.provinsiList.findIndex((record) => record.id === newValue.id);
    //             // console.log(index)
    //             if (index > -1) {
    //                 this.kabupaten_index(this.provinsiList[index].id);
    //             }

    //             this.kabupatenList = [];
    //             this.kecamatanList = [];
    //             this.kelurahanList = [];

    //             // this.item.city = ''
    //             // this.item.district = ''
    //             // this.item.sub_district = ''
    //         }
    //     },
    //     'item.city': {
    //         handler(newValue) {
    //             const index = this.kabupatenList.findIndex((record) => record.id === newValue.id);
    //             // console.log(index)
    //             if (index > -1) {
    //                 this.kecamatan_index(this.kabupatenList[index].id);
    //             }
    //             this.kecamatanList = [];
    //             this.kelurahanList = [];

    //             // this.item.district = ''
    //             // this.item.sub_district = ''
    //         }
    //     },
    //     'item.district': {
    //         handler(newValue) {
    //             // console.log(newValue);


    //             const index = this.kecamatanList.findIndex((record) => record.id === newValue.id);
    //             // console.log(index);
    //             if (index > -1) {
    //                 this.kelurahan_index(this.kecamatanList[index].id);
    //             }

    //             this.kelurahanList = [];


    //             // this.item.sub_district = ''
    //         }
    //     },

    //     'item.sub_district': {
    //         handler(newValue) {
    //             // console.log(newValue);
    //             const index = this.kecamatanList.findIndex((record) => record.id === newValue.id);
    //             console.log(index);

    //         }
    //     },


    // },

    methods: {
        back() {

            if (localStorage.getItem('history_page') == null) {
                goPage("customer");
            } else {
                const page = localStorage.getItem('history_page');
                localStorage.removeItem('history_page');
                goPage(page);
            }

        },
        // nameWithLang ({province}) {
        //     return `${province}`
        // },
        // nameWithLangCity ({city}) {
        //     return `${city}`
        // },
        // nameWithLangDistrict ({district}) {
        //     return `${district}`
        // },
        // nameWithLangSubdistrict ({sub_district}) {
        //     return `${sub_district}`
        // },

        handleProvince(index) {
            this.kabupatenList = [];
            this.kecamatanList = [];
            this.kelurahanList = [];

            // Clear the selected city, district, and sub-district from the address as well
            this.currentData.address[index].city = null;
            this.currentData.address[index].district = null;
            this.currentData.address[index].sub_district = null;

            // const indexProvinsi = this.provinsiList.findIndex((record) => record.id === this.currentData.address[index].province.id);
            //     console.log(indexProvinsi)
            //     if (indexProvinsi > -1) {
            //         this.kabupaten_index(this.provinsiList[indexProvinsi].id);
            //     }

            const province = this.currentData.address[index].province;
            if (province && province.id) { // Ensure province exists and has an id
                const indexProvinsi = this.provinsiList.findIndex((record) => record.id === province.id);
                console.log(indexProvinsi);
                if (indexProvinsi > -1) {
                    this.kabupaten_index(this.provinsiList[indexProvinsi].id);
                }
            }



        },

        handleCity(index) {
            console.log('tes')
            this.kecamatanList = [];
            this.kelurahanList = [];

            this.currentData.address[index].district = null;
            this.currentData.address[index].sub_district = null;

            // const indexCity = this.kabupatenList.findIndex((record) => record.id === this.currentData.address[index].city.id);
            //     // console.log(index)
            //     if (indexCity > -1) {
            //         this.kecamatan_index(this.kabupatenList[indexCity].id);
            //     }

            const city = this.currentData.address[index].city;
            // console.log("Current selected district:", city); // Log the current selected district
            // console.log("Current selected district ID:", city ? city.id : "No district selected"); // Log the district ID if available
            // console.log("Current kecamatanList:", this.kabupatenList);

            if (city && city.id) { // Ensure city exists and has an id
                const indexCity = this.kabupatenList.findIndex((record) => record.id === city.id);
                console.log("Index of selected district in kabupatenList:", indexCity);
                if (indexCity > -1) {
                    this.kecamatan_index(this.kabupatenList[indexCity].id);
                }
            }



        },

        handleDistrict(index) {
            console.log('ini index kecamatan', index)
            this.kelurahanList = [];

            this.currentData.address[index].sub_district = null;

            // const indexDistrict = this.kecamatanList.findIndex((record) => record.id === this.currentData.address[index].district.id);
            //     // console.log(indexDistrict)
            //     if (indexDistrict > -1) {
            //         this.kelurahan_index(this.kecamatanList[indexDistrict].id);
            //     }

            const district = this.currentData.address[index].district;
            console.log("Current selected district:", district); // Log the current selected district
            console.log("Current selected district ID:", district ? district.id : "No district selected"); // Log the district ID if available
            console.log("Current kecamatanList:", this.kecamatanList);

            if (district && district.id) { // Ensure district exists and has an id
                const indexDistrict = this.kecamatanList.findIndex((record) => record.id === district.id);
                console.log("Index of selected district in kecamatanList:", indexDistrict);
                if (indexDistrict > -1) {
                    this.kelurahan_index(this.kecamatanList[indexDistrict].id);
                }
            }




        },

        handleSub_district(index) {
            const sub_district = this.currentData.address[index].sub_district;
            if (sub_district && sub_district.id) { // Ensure sub-district exists and has an id
                const indexSub_district = this.kelurahanList.findIndex((record) => record.id === sub_district.id);
                if (indexSub_district > -1) {
                    // Perform your logic if needed
                }
            }

            // const indexSub_district = this.kelurahanList.findIndex((record) => record.id === this.currentData.address[index].sub_district.id);
            //     // console.log(indexSub_district)
            //     if (indexSub_district > -1) {
            //         // this.kelurahan_index(this.kecamatanList[indexDistrict].id);
            //         // this.currentData.address[index].sub_district = this.kecamatanList
            //     }


            // this.kelurahanList = [];

        },

        selectButton(button) {
            this.selectedButton = button; // Set the clicked button as selected
            this.currentData.type = button;
        },

        addRowAlamat() {
            this.currentData.address.push({
                address: '',
                province: null,
                city: null,
                district: null,
                sub_district: null,
                pos_code: '',
            });
        },

        // addDeposit() {
        //     this.currentData.deposit.push({
        //         date: '',
        //         name: '',
        //         bank_name: '',
        //         amount: ''
        //     });
        // },

        // formatCurrency(label, number,index) {
        //     this.currentData.deposit[index][label] = rupiahInput(number);

        // },

        // Remove a deposit row
        removeDeposit(index) {
            this.currentData.address.splice(index, 1);
        },

        checkingLastRow(index) {
            // if(index == this.currentData.address.length - 1) {
            //     // this.records[index].isAdded = true;
            //     return true;

            //         // this.records.splice(index + 1, 0, );
            // }else {
            //     // this.records[index].isAdded = false;
            //     return false;

            // }
            return index === this.currentData.address.length - 1;
        },

        onProvinceSelect(selectedProvince) {
            this.item.province = selectedProvince;
            this.kabupatenList = []; // Reset city list
            this.kecamatanList = []; // Reset district list
            this.kelurahanList = []; // Reset sub-district list
            this.kabupaten_index(selectedProvince.id); // Load cities based on province
        },

        onCitySelect(selectedCity) {
            this.item.city = selectedCity;
            this.kecamatanList = []; // Reset district list
            this.kelurahanList = []; // Reset sub-district list
            this.kecamatan_index(selectedCity.id); // Load districts based on city


        },

        onDistrictSelect(selectedDistrict) {
            this.item.district = selectedDistrict;
            this.kelurahanList = []; // Reset sub-district list
            this.kelurahan_index(selectedDistrict.id); // Load sub-districts based on 

        },

        insert() {
            ShowLoading();
            let formData = new FormData();

            // Ensure customer_type is included in currentData
            this.currentData.type = this.selectedButton;

            // console.log(this.currentData.type)

            // this.currentData.deposit.forEach((item) => {
            //     // Apply rupiahOutput to remove "Rp." and format amount
            //     item.amount = rupiahoutput(item.amount);
            // });



            Object.entries(this.currentData).forEach(([key, value]) => {
                formData.append(key, value);
            });

            // console.log('cek id provinsi dkk :',this.currentData.province.id)


            // for test try seperately the deposit row
            // this.currentData.deposit.forEach((deposit, index) => {
            //     // Append each deposit field as deposit[0], deposit[1], etc.
            //     Object.entries(deposit).forEach(([key, value]) => {
            //         // formData.delete("deposit");
            //         formData.append(`deposit[${index}][${key}]`, value);
            //     });
            // });

            // formData.delete("province");
            // formData.append("province", this.currentData.province.text);
            // formData.delete("city");
            // formData.append("city", this.currentData.city.text);
            // formData.delete("district");
            // formData.append("district", this.currentData.district.text);
            // formData.delete("sub_district");
            // formData.append("sub_district", this.currentData.sub_district.text);

            let addressArray = []
            this.currentData.address.forEach((addr, index) => {
                let item_prepare = addr
                item_prepare.province = addr.province.text
                item_prepare.city = addr.city.text
                item_prepare.district = addr.district.text
                item_prepare.sub_district = addr.sub_district.text
                addressArray.push(item_prepare)
            });



            formData.delete("address");
            formData.append('address', JSON.stringify(addressArray));

            // formData.delete("deposit");
            // formData.append('deposit', JSON.stringify(this.currentData.deposit));


            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            // CloseLoading()
            // return true


            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'customer_insert';
            } else {
                this.api = base_url + 'customer_insert';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        // this.deposits = response.data.deposit
                        AlertBottom(response.data.message);


                        if (localStorage.getItem('history_page') == null) {
                            goPage("customer");
                        } else {
                            const page = localStorage.getItem('history_page');
                            localStorage.removeItem('history_page');
                            goPage(page);
                        }

                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        provinsi_index() {
            ShowLoading();

            axios
                .post(base_url_wilayah_indonesia + "provinsi_index", '')
                .then((response) => {
                    if (response.data.status == true) {
                        // AlertPopup("success", response.data.message, "", 1500, false);
                        this.provinsiList = response.data.data.map((item) => ({
                            text: item.nama,
                            id: item.id,
                            value: item.nama,
                        }));

                    }
                    if (response.data.status == false) {
                        // AlertPopup("error", "", response.data.message, 1500, false);
                    }
                    CloseLoading();
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

        kabupaten_index(id) {
            this.kabupatenList = [];
            ShowLoading();
            const formData = new FormData();
            formData.append("id", id);

            axios
                .post(base_url_wilayah_indonesia + "kabupaten_index", formData)
                .then((response) => {
                    if (response.data.status == true) {

                        this.kabupatenList = response.data.data.map((item) => ({
                            text: item.nama,
                            id: item.id,
                            value: item.nama,
                        }))
                    } else {
                        //as
                    }
                    CloseLoading();
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });


        },
        kecamatan_index(id) {
            this.kecamatanList = [];
            ShowLoading();
            const formData = new FormData();
            formData.append("id", id);
            axios
                .post(base_url_wilayah_indonesia + "kecamatan_index", formData)
                .then((response) => {
                    if (response.data.status == true) {
                        this.kecamatanList = response.data.data.map((item) => ({
                            text: item.nama,
                            id: item.id,
                            value: item.nama,
                        }))
                    } else {
                        //as
                    }
                    CloseLoading();
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });


        },

        kelurahan_index(id) {
            this.kelurahanList = [];
            ShowLoading();
            const formData = new FormData();
            formData.append("id", id);
            axios
                .post(base_url_wilayah_indonesia + "kelurahan_index", formData)
                .then((response) => {
                    if (response.data.status == true) {
                        this.kelurahanList = response.data.data.map((item) => ({
                            text: item.nama,
                            id: item.id,
                            value: item.nama,
                        }))
                    } else {
                        //as
                    }
                    CloseLoading();
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });


        },

        // openModalDelete(index) {
        //   this.currentDeleteIndex = index;

        //   this.isModalOpen = true;
        // },
        // closeModal() {
        //     this.isModalOpen = false;
        // },

        // confirmDelete() {
        //   // Handle the actual deletion here
        //   if (this.currentDeleteIndex !== null) {
        //       this.currentData.deposits.splice(this.currentDeleteIndex, 1);  // Delete the item
        //       this.closeModal();  // Close the modal after deletion
        //   }
        // },



        // goBack() {
        //     // Retrieve the previous route from localStorage
        //     const previousRoute = localStorage.getItem('previousRoute');
        //     if (previousRoute) {
        //         // Navigate to the previous route
        //         this.$router.push(previousRoute);
        //         // Optionally clear the saved route
        //         localStorage.removeItem('previousRoute');
        //     } else {
        //         // Fallback route if no previous route is found
        //         this.$router.push({ name: 'dashboard' });
        //     }
        // },


    },
}
</script>