<template>
    <NavBar />
    <div class=" justify-center mt-5 px-10">
        <div class="mb-12 flex justify-center">
            <h2 class="text-5xl font-redHat font-bold">Perbaharui Customer</h2>
        </div>
        <form @submit.prevent="insert">
            <div class="mb-20 px-6 mx-3">
                <div class="grid grid-cols-3 gap-4">
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Instagram</label>
                        <input type="text" v-model="currentData.instagram"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="instagram"
                            placeholder="" required>

                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Nama Customer</label>
                        <input type="text" v-model="currentData.name"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="name" placeholder=""
                            required>

                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">No. Whatsapp</label>
                        <input type="text" v-model="currentData.whatsapp"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="whatsapp" placeholder=""
                            required>

                    </div>

                </div>

                <!-- tipe customer -->
                <div class="button-group grid grid-cols-2 mt-5 gap-4">
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Tipe Customer</label>
                        <button type="button" class="w-full text-center items-center border-1 rounded py-2"
                            :class="{ 'bg-[#F5CA40] text-white': selectedButton === 'Reguler' }"
                            @click="selectButton('Reguler')">Reguler</button>
                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2"></label>
                        <button type="button" class="mt-4 w-full text-center items-center border-1 rounded py-2"
                            :class="{ 'bg-[#F5CA40] text-white': selectedButton === 'Reseller' }"
                            @click="selectButton('Reseller')">Reseller</button>
                    </div>
                </div>


                <div class="bg-[#F5F5F5] rounded-[8px] grid grid-cols-1 gap-1 px-4 py-4 mt-5 relative">



                    <div class="relative" v-for="(item, index) in currentData.address" :key="index">

                        <div class="grid grid-cols-1 mt-3">
                            <button type="button" v-if="checkingLastRowAddress(index)" @click="addRowAlamat"
                                class="absolute top-5 right-5 text-[#5FBAE6F2] text-[24px]"><i
                                    class="bi bi-plus-circle"></i></button>
                            <button type="button" v-if="!checkingLastRowAddress(index)" @click="removeRowAddress(index)"
                                class=" absolute text-red-500 top-5 right-5 text-[24px]">
                                <i class="bi bi-trash"></i>
                            </button>
                        </div>

                        <!-- Address Section -->
                        <div class="grid grid-cols-12 mt-3">
                            <div class="col-span-12">
                                <label for="alamat"
                                    class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Alamat</label>
                                <textarea name="address" rows="4"
                                    class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" v-model="item.address"
                                    id="address"></textarea>
                            </div>
                        </div>



                        <!-- Province, City, etc. -->
                        <div class="grid grid-cols-4 gap-4 mt-5">
                            <div>
                                <label
                                    class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Provinsi</label>
                                <multiselect v-model="item.province" :options="provinsiList" label="text" track-by="id"
                                    placeholder="Pilih Provinsi" class="multiselect-full-width" :selectLabel="''"
                                    :deselectLabel="''" @update:modelValue="handleProvince(index)"></multiselect>
                            </div>

                            <div>
                                <label
                                    class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kota/Kabupaten</label>
                                <multiselect v-model="item.city" :options="kabupatenList"
                                    placeholder="Pilih Kota/Kabupaten" label="text" track-by="id"
                                    class="multiselect-full-width" :selectLabel="''" :deselectLabel="''"
                                    @update:modelValue="handleCity(index)" @click="handleProvince(index)"></multiselect>
                            </div>

                            <div>
                                <label
                                    class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kecamatan</label>
                                <multiselect v-model="item.district" :options="kecamatanList"
                                    placeholder="Pilih Kecamatan" label="text" track-by="id"
                                    class="multiselect-full-width" :selectLabel="''" :deselectLabel="''"
                                    @update:modelValue="handleDistrict(index)" @click="handleCity(index)"></multiselect>
                            </div>

                            <div>
                                <label
                                    class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kelurahan</label>
                                <multiselect v-model="item.sub_district" :options="kelurahanList"
                                    placeholder="Pilih Kelurahan" label="text" track-by="id"
                                    class="multiselect-full-width" :selectLabel="''" :deselectLabel="''"
                                    @update:modelValue="handleSub_district(index)" @click="handleDistrict(index)">
                                </multiselect>
                            </div>

                            <div>
                                <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kode
                                    Pos</label>
                                <input type="text" v-model="item.pos_code"
                                    class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="">
                            </div>
                        </div>
                    </div>

                </div>

                <!-- address section -->
                <!-- <div class="grid grid-cols-12 mt-5">
                    <div class="col-span-12">
                        <label for="alamat"
                            class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Alamat</label>
                        <textarea name="address" rows="4" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"
                            v-model="currentData.address" id="address" ></textarea>

                    </div>
                </div> -->

                <!-- provinsi section -->
                <!-- <div class="grid grid-cols-4 gap-4 mt-5">
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Provinsi</label>
                        <multiselect v-model="currentData.province" :options="provinsiList" label="text" track-by="id"
                            placeholder="Pilih Provinsi" :selectLabel="''" :deselectLabel="''"
                            class="multiselect-full-width"></multiselect>

                            

                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kota/Kabupaten</label>
                        <multiselect v-model="currentData.city" :options="kabupatenList"
                            placeholder="Pilih Kota/Kabupaten" label="text" track-by="id" :selectLabel="''"
                            :deselectLabel="''" class="multiselect-full-width"></multiselect>

                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kecamatan</label>
                        <multiselect v-model="currentData.district" :options="kecamatanList"
                            placeholder="Pilih kecamatan" label="text" track-by="id" :selectLabel="''"
                            :deselectLabel="''" class="multiselect-full-width"></multiselect>

                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kelurahan</label>
                        <multiselect v-model="currentData.sub_district" :options="kelurahanList"
                            placeholder="Pilih Kelurahan" label="text" track-by="id" :selectLabel="''"
                            :deselectLabel="''" class="multiselect-full-width"></multiselect>

                    </div>

                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Kode Pos</label>
                        <input type="text" v-model="currentData.pos_code"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="">

                    </div>
                </div> -->

                <div class="bg-[#F5F5F5] rounded-[8px] grid grid-cols-1 gap-1 px-4 py-4 mt-5">
                    <div class="">
                        <label class=" font-redHat text-[#8F8F8F] font-medium font-2xl mb-3">Deposit</label>
                        <div class="flex gap-3 w-full mb-2" v-for="(item, index) in currentData.deposit" :key="index">
                            <!-- <input type="date" v-model="currentData.date" class=" flex-grow border-1 border-[#5FBAE6] rounded-md p-2 " name="date" >
                            <input type="text" v-model="currentData.name" class=" flex-grow border-1 border-[#5FBAE6] rounded-md p-2 " name="atas_nama" placeholder="Atas Nama">
                            <input type="text" v-model="currentData.bank_name" class="flex-grow border-1 border-[#5FBAE6] rounded-md p-2 " name="bank" placeholder="Nama Bank">
                            <input type="text" v-model="currentData.amount"  class=" flex-grow border-1 border-[#5FBAE6] rounded-md p-2" name="jumlah" placeholder="Jumlah"> -->

                            <!-- /////// -->

                            <input type="date" v-model="item.date" @focus="setDefaultDate(item)"
                                class="flex-grow border-1 border-[#5FBAE6] rounded-md p-2">
                            <input type="text" v-model="item.name"
                                class="flex-grow border-1 border-[#5FBAE6] rounded-md p-2" placeholder="Atas Nama">
                            <input type="text" v-model="item.bank_name"
                                class="flex-grow border-1 border-[#5FBAE6] rounded-md p-2" placeholder="Nama Bank">
                            <input type="text" v-model="item.amount"
                                @input="formatCurrency('amount', item.amount, index)"
                                class="flex-grow border-1 border-[#5FBAE6] rounded-md p-2" placeholder="Rp. 0,-">
                            <button type="button" v-if="!checkingLastRow(index)" @click="removeDeposit(index)"
                                class="text-red-500 text-[24px]">
                                <i class="bi bi-trash"></i>
                            </button>
                            <button type="button" v-if="checkingLastRow(index)" @click="addDeposit"
                                class="text-[#5FBAE6F2] text-[24px]"><i class="bi bi-plus-circle"></i></button>


                        </div>

                    </div>
                    <!-- <div class="">
                        <label class="font-redHat text-[#8F8F8F] font-medium font-2xl"></label>
                            
                    </div>
                    <div class="">
                        <label class=" font-redHat text-[#8F8F8F] font-medium font-2xl"></label>
                            
                    </div>
                    <div class="">
                        <label class=" font-redHat text-[#8F8F8F] font-medium font-2xl"></label>
                            
                    </div> -->
                    <!-- <div class="">
                        <button class="text-[#5FBAE6F2] w-[87px] h-[88px]"><i class="bi bi-plus-circle"></i></button>
                    </div> -->
                </div>










            </div>

            <div class="mb-3 space-x-5 flex justify-end px-10 ">
                <!-- <RouterLink to="customer" class="bg-white text-xl font-redHat text-black text-center py-2 px-4 rounded font-bold border border-[#5FBAE6] w-[240px]">Batal</RouterLink> -->
                <button @click="handleBack"
                    class="bg-white text-xl font-redHat text-black text-center py-2 px-4 rounded font-bold border border-[#5FBAE6] w-[240px]">Batal</button>
                <button type="submit"
                    class="bg-[#F5CA40] text-black text-xl font-redHat text-center py-2 px-4 rounded font-bold w-[240px]">Simpan</button>
            </div>
        </form>


    </div>
</template>

<style scoped>
.selected {
    background-color: #F5CA40;
    color: white;
}
</style>



<script>
import NavBar from '@/components/NavBar.vue';
import axios from 'axios';
import { ErrorConnectionTimeOut, goPage, ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom, base_url_wilayah_indonesia, rupiahInput, rupiahoutput } from "@/assets/js/function";
// import {ShowLoading, CloseLoading, base_url_wilayah_indonesia, ErrorConnectionTimeOut, rupiahInput, rupiahoutput } from "@/assets/js/function";
import Cookies from "js-cookie";
import Multiselect from 'vue-multiselect';


export default {
    components: {
        NavBar,
        Multiselect,

    },

    data() {
        return {
            // currentData: [],
            currentData: {
                type: '', // Add customer_type in currentData
                deposit: [
                ],
                address: [
                    {
                        address: '',
                        province: '',
                        city: '',
                        district: '',
                        sub_district: '',
                        pos_code: '',
                    },
                    {
                        address: '',
                        province: '',
                        city: '',
                        district: '',
                        sub_district: '',
                        pos_code: ''
                    }
                ],
                name: "",
                instagram: "",
                whatsapp: "",
            },
            selectedButton: null, // to track the selected button
            // for alamat
            provinsiList: [],
            kabupatenList: [],
            kecamatanList: [],
            kelurahanList: [],

            statusLoad: false,

        };
    },

    created() {
        const token = Cookies.get("token");
        // console.log(token)
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 30000,
        }

        // console.log(this.config);
        // this.index();
        //get data
        // this.currentData = JSON.parse(localStorage.getItem('currentData'));
        // console.log(this.currentData);
        // this.dataDeposit = JSON.parse(localStorage.getItem('data_deposit'));



        // Initialize selectedButton based on currentData's customer_type
        // if (this.currentData && this.currentData.type) {
        //     this.selectedButton = this.currentData.type; // Set selectedButton to match existing customer_type
        // }

        // console.log(this.currentData.type)

        if (!this.currentData.type) {
            this.currentData.type = 'Reguler'; // Set default type
            this.selectedButton = 'Reguler'; // Set default button
        } else {
            this.selectedButton = this.currentData.type; // Set button based on existing type
        }




        // this.prepareData();

        // this.provinsi_index();


    },

    // watch: {
    //     'currentData.province': {
    //         handler(newValue) {
    //             if (newValue && newValue.id !== '') {
    //                 const index = this.provinsiList.findIndex((record) => record.id === newValue.id);
    //                 if (index > -1) {
    //                     this.kabupaten_index(this.provinsiList[index].id);
    //                 }
    //             }
    //             // const index = this.provinsiList.findIndex((record) => record.id === newValue.id);
    //             // if (index > -1) {
    //             //     this.kabupaten_index(this.provinsiList[index].id);
    //             // }

    //              // Reset only if not loading
    //             if (!this.statusLoad) {
    //                 this.kabupatenList = [];
    //                 this.kecamatanList = [];
    //                 this.kelurahanList = [];
    //                 this.currentData.city = '';
    //                 this.currentData.district = '';
    //                 this.currentData.sub_district = '';
    //             }

    //             // this.kabupatenList = [];
    //             // this.kecamatanList = [];
    //             // this.kelurahanList = [];

    //             // this.currentData.city = ''
    //             // this.currentData.district = ''
    //             // this.currentData.sub_district = ''

    //         }
    //     },
    //     'currentData.city': {
    //         handler(newValue) {
    //             if(newValue && newValue.id !== ''){

    //                 const index = this.kabupatenList.findIndex((record) => record.id === newValue.id);
    //                 if (index > -1) {
    //                     this.kecamatan_index(this.kabupatenList[index].id);
    //                 }
    //             }

    //              // Reset only if not loading
    //             if (!this.statusLoad) {

    //                 this.kecamatanList = [];
    //                 this.kelurahanList = [];

    //                 this.currentData.district = '';
    //                 this.currentData.sub_district = '';
    //             }

    //             // this.kecamatanList = [];
    //             // this.kelurahanList = [];

    //             // this.currentData.district = ''
    //             // this.currentData.sub_district = ''


    //         }
    //     },
    //     'currentData.district': {
    //         handler(newValue) {
    //             // console.log(newValue);
    //             if(newValue && newValue.id !== ''){

    //                 const index = this.kecamatanList.findIndex((record) => record.id === newValue.id);
    //                 // console.log(index);
    //                 if (index > -1) {
    //                     this.kelurahan_index(this.kecamatanList[index].id);
    //                 }
    //             }

    //             if (!this.statusLoad) {

    //                 this.kelurahanList = [];


    //                 this.currentData.sub_district = '';
    //             }

    //             // this.currentData.sub_district = ''
    //         }
    //     },

    //     'currentData.sub_district': {

    //         handler(newValue) {
    //             if(newValue && newValue.id !== ''){

    //                 // console.log(newValue);
    //                 const index = this.kecamatanList.findIndex((record) => record.id === newValue.id);
    //                 console.log(index);
    //             }

    //         }
    //     },


    // },

    methods: {
        handleBack() {
            const lastPage = sessionStorage.getItem('lastPage');
            if (lastPage === 'verifikasi-live-ig') {
                this.$router.push({ name: 'verifikasiliveig' });
            } else if (lastPage === 'list-order') {
                // this.$router.push({ name: 'listorder' });
                goPage('listorder')
            } else {
                // this.$router.push({ name: 'customer' });
                goPage('customer')
            }
        },
        selectButton(button) {
            this.selectedButton = button; // Set the clicked button as selected
            this.currentData.type = button;
        },
        addDeposit() {
            const currentDate = new Date().toISOString().split("T")[0];
            this.currentData.deposit.push({
                date: currentDate,
                name: '',
                bank_name: '',
                amount: ''
            });
        },
        addRowAlamat() {
            this.currentData.address.push({
                address: '',
                province: null,
                city: null,
                district: null,
                sub_district: null,
                pos_code: '',
            });
        },
        // Remove a deposit row
        removeDeposit(index) {
            this.currentData.deposit.splice(index, 1);
        },
        removeRowAddress(index) {
            this.currentData.address.splice(index, 1);
        },
        checkingLastRowAddress(index) {
            // if(index == this.currentData.address.length - 1) {
            //     // this.records[index].isAdded = true;
            //     return true;

            //         // this.records.splice(index + 1, 0, );
            // }else {
            //     // this.records[index].isAdded = false;
            //     return false;

            // }
            return index === this.currentData.address.length - 1;
        },
        formatCurrency(label, number, index) {
            this.currentData.deposit[index][label] = rupiahInput(number);

        },
        setDefaultDate(item) {
            if (!item.date) {
                const currentDate = new Date().toISOString().split("T")[0]; // Get the current date in YYYY-MM-DD format
                item.date = currentDate;
            }
        },

        checkingLastRow(index) {
            if (index == this.currentData.deposit.length - 1) {
                // this.records[index].isAdded = true;
                return true;

                // this.records.splice(index + 1, 0, );
            } else {
                // this.records[index].isAdded = false;
                return false;

            }
        },

        handleProvince(index) {
            this.kabupatenList = [];
            this.kecamatanList = [];
            this.kelurahanList = [];

            this.currentData.address[index].city = null;
            this.currentData.address[index].district = null;
            this.currentData.address[index].sub_district = null;


            const province = this.currentData.address[index].province;
            if (province && province.id) { // Ensure province exists and has an id
                const indexProvinsi = this.provinsiList.findIndex((record) => record.id === province.id);
                console.log(indexProvinsi);
                if (indexProvinsi > -1) {
                    this.kabupaten_index(this.provinsiList[indexProvinsi].id);
                }
            }
            // if (!this.statusLoad) {
            //     this.kabupatenList = [];
            //     this.kecamatanList = [];
            //     this.kelurahanList = [];
            //     this.currentData.city = '';
            //     this.currentData.district = '';
            //     this.currentData.sub_district = '';
            // }



        },

        handleCity(index) {

            this.kecamatanList = [];
            this.kelurahanList = [];


            this.currentData.address[index].district = null;
            this.currentData.address[index].sub_district = null;

            // const indexCity = this.kabupatenList.findIndex((record) => record.id === this.currentData.address[index].city.id);
            //     // console.log(index)
            //     if (indexCity > -1) {
            //         this.kecamatan_index(this.kabupatenList[indexCity].id);
            //     }

            const city = this.currentData.address[index].city;
            if (city && city.id) { // Ensure city exists and has an id
                const indexCity = this.kabupatenList.findIndex((record) => record.id === city.id);
                if (indexCity > -1) {
                    this.kecamatan_index(this.kabupatenList[indexCity].id);
                }
            }

            // if (!this.statusLoad) {

            //     this.kecamatanList = [];
            //     this.kelurahanList = [];

            //     this.currentData.district = '';
            //     this.currentData.sub_district = '';
            // }

            // this.kecamatanList = [];
            // this.kelurahanList = [];


        },

        handleDistrict(index) {

            this.kelurahanList = [];



            this.currentData.address[index].sub_district = null;

            // const indexDistrict = this.kecamatanList.findIndex((record) => record.id === this.currentData.address[index].district.id);
            //     // console.log(index)
            //     if (indexDistrict > -1) {
            //         this.kelurahan_index(this.kecamatanList[indexDistrict].id);
            //     }

            const district = this.currentData.address[index].district;
            if (district && district.id) { // Ensure district exists and has an id
                const indexDistrict = this.kecamatanList.findIndex((record) => record.id === district.id);
                if (indexDistrict > -1) {
                    this.kelurahan_index(this.kecamatanList[indexDistrict].id);
                }
            }

            // if (!this.statusLoad) {

            //     this.kelurahanList = [];


            //     this.currentData.sub_district = '';
            // }


            // this.kelurahanList = [];

        },

        handleSub_district(index) {
            // const indexSub_district = this.kecamatanList.findIndex((record) => record.id === this.currentData.address[index].sub_district.id);
            //     // console.log(indexSub_district)
            //     if (indexSub_district > -1) {
            //         // this.kelurahan_index(this.kecamatanList[indexDistrict].id);
            //         // this.currentData.address[index].sub_district = this.kecamatanList
            //     }

            const sub_district = this.currentData.address[index].sub_district;
            if (sub_district && sub_district.id) { // Ensure sub-district exists and has an id
                const indexSub_district = this.kelurahanList.findIndex((record) => record.id === sub_district.id);
                if (indexSub_district > -1) {
                    // Perform your logic if needed
                }
            }


            // this.kelurahanList = [];

        },

        onProvinceSelect(selectedProvince) {
            this.currentData.province = selectedProvince;
            this.kabupatenList = []; // Reset city list
            this.kecamatanList = []; // Reset district list
            this.kelurahanList = []; // Reset sub-district list
            this.kabupaten_index(selectedProvince.id); // Load cities based on province
        },

        onCitySelect(selectedCity) {
            this.currentData.city = selectedCity;
            this.kecamatanList = []; // Reset district list
            this.kelurahanList = []; // Reset sub-district list
            this.kecamatan_index(selectedCity.id); // Load districts based on city
        },

        onDistrictSelect(selectedDistrict) {
            this.currentData.district = selectedDistrict;
            this.kelurahanList = []; // Reset sub-district list
            this.kelurahan_index(selectedDistrict.id); // Load sub-districts based on district
        },
        onSubDistrictSelect(selectedSubDistrict) {
            this.currentData.sub_district = selectedSubDistrict;
            this.kelurahanList = []; // Reset sub-district list
            this.kelurahan_index(selectedSubDistrict.id); // Load sub-districts based on district
        },

        insert() {
            ShowLoading();
            let formData = new FormData();

            // Ensure customer_type is included in currentData
            this.currentData.type = this.selectedButton;

            // console.log(this.currentData.type)

            // Clean up the deposit data (remove 'Rp.' from the amount)
            this.currentData.deposit.forEach((item) => {
                // Apply rupiahOutput to remove "Rp." and format amount
                item.amount = rupiahoutput(item.amount);
            });


            Object.entries(this.currentData).forEach(([key, value]) => {
                formData.append(key, value);
            });

            // console.log('cek id provinsi dkk :',this.currentData.province.id)

            // for test try seperately the deposit row 
            // this.currentData.deposit.forEach((deposit, index) => {
            //     // Append each deposit field as deposit[0], deposit[1], etc.
            //     Object.entries(deposit).forEach(([key, value]) => {
            //         // formData.delete("deposit");
            //         formData.append(`deposit[${index}][${key}]`, value);
            //     });
            // });

            // formData.delete("province");
            // if(this.currentData.province != null){

            //     formData.append("province", this.currentData.province.text);
            // }
            // formData.delete("city");
            // if(this.currentData.city != null){
            //     formData.append("city", this.currentData.city.text);
            // }
            // formData.delete("district");

            // if(this.currentData.district != null){
            //     formData.append("district", this.currentData.district.text);
            // }
            // formData.delete("sub_district");

            // if(this.currentData.sub_district != null){
            //     formData.append("sub_district", this.currentData.sub_district.text);
            // }

            let addressArray = []
            this.currentData.address.forEach((addr, index) => {
                let item_prepare = addr
                item_prepare.province = addr.province.text
                item_prepare.city = addr.city.text
                item_prepare.district = addr.district.text
                item_prepare.sub_district = addr.sub_district.text
                addressArray.push(item_prepare)
            });

            formData.delete("address");
            formData.append('address', JSON.stringify(addressArray));

            formData.delete("deposit");
            formData.append('deposit', JSON.stringify(this.currentData.deposit));

            // formData.delete("amount");
            // formData.append('amount', rupiahoutput(item.amount));

            // this.currentData.deposit.forEach((item) => {
            //     // console.log('Original Amount:', item.amount);  // Log original amount
            //     // console.log('Processed Amount:', rupiahoutput(item.amount));
            //     formData.delete("amount");
            //     formData.append('amount', rupiahoutput(item.amount));

            //     // item.amount = rupiah(item.amount)
            // });

            for (let [key, value] of formData.entries()) {
                console.log(`${key}: ${value}`);
            }
            // CloseLoading()
            // return true




            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'customer_update';
            } else {
                this.api = base_url + 'customer_update';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        AlertBottom(response.data.message);
                        // Check previousPage to determine where to redirect
                        let previousPage = sessionStorage.getItem('lastPage');

                        if (previousPage === 'verifikasi-live-ig') {
                            goPage('verifikasiliveig');
                        } else if (previousPage === 'list-order') {
                            // this.$router.push({ name: 'listorder' });
                            goPage('listorder')
                        } else {
                            goPage('customer');
                        }


                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },


        async provinsi_index() {
            ShowLoading();
            return new Promise((resolve, reject) => {
                axios.post(base_url_wilayah_indonesia + "provinsi_index", '')
                    .then((response) => {
                        // console.log(response);
                        if (response.data.status === true) {
                            this.provinsiList = response.data.data.map((item) => ({
                                text: item.nama,
                                id: item.id,
                                value: item.nama,
                            }));
                            resolve(response); // Resolve the promise with the response
                        } else {
                            reject(new Error("Failed to load provinces")); // Reject the promise
                        }
                        CloseLoading();
                    })
                    .catch((error) => {
                        ErrorConnectionTimeOut(error);
                        reject(error); // Reject the promise with the error
                    });
            });
        },

        async kabupaten_index(id) {
            this.kabupatenList = [];
            ShowLoading();
            const formData = new FormData();
            formData.append("id", id);

            try {
                const response = await axios.post(base_url_wilayah_indonesia + "kabupaten_index", formData);
                if (response.data.status === true) {
                    this.kabupatenList = response.data.data.map((item) => ({
                        text: item.nama,
                        id: item.id,
                        value: item.nama,
                    }));
                }
                CloseLoading();
            } catch (error) {
                ErrorConnectionTimeOut(error);
                CloseLoading();
            }
        },
        async kecamatan_index(id) {
            this.kecamatanList = [];
            ShowLoading();
            const formData = new FormData();
            formData.append("id", id);

            try {
                const response = await axios.post(base_url_wilayah_indonesia + "kecamatan_index", formData);
                if (response.data.status === true) {
                    this.kecamatanList = response.data.data.map((item) => ({
                        text: item.nama,
                        id: item.id,
                        value: item.nama,
                    }));
                }
                CloseLoading();
            } catch (error) {
                ErrorConnectionTimeOut(error);
                CloseLoading();
            }
        },

        async kelurahan_index(id) {
            this.kelurahanList = [];
            ShowLoading();
            const formData = new FormData();
            formData.append("id", id);

            try {
                const response = await axios.post(base_url_wilayah_indonesia + "kelurahan_index", formData);
                if (response.data.status === true) {
                    this.kelurahanList = response.data.data.map((item) => ({
                        text: item.nama,
                        id: item.id,
                        value: item.nama,
                    }));
                }
                CloseLoading();
            } catch (error) {
                ErrorConnectionTimeOut(error);
                CloseLoading();
            }
        },


        async prepareData() {

            this.statusLoad = true;
            await this.provinsi_index();

            if (!this.currentData.address || this.currentData.address.length === 0) {
                this.currentData.address = [{
                    province: null,
                    city: null,
                    district: null,
                    sub_district: null,
                    pos_code: ''
                }];
            }

            for (let i = 0; i < this.currentData.address.length; i++) {
                let currentAddress = this.currentData.address[i];

                // Find the index of the province in the province list
                const indexProvinsi = this.provinsiList.findIndex((record) => record.text === currentAddress.province);
                if (indexProvinsi > -1) {
                    currentAddress.province = this.provinsiList[indexProvinsi]; // Set the province object
                    await this.kabupaten_index(this.provinsiList[indexProvinsi].id); // Load city (kabupaten) list for the province

                    // Find the index of the city (kabupaten) in the city list
                    const indexKabupaten = this.kabupatenList.findIndex((record) => record.text === currentAddress.city);
                    if (indexKabupaten > -1) {
                        currentAddress.city = this.kabupatenList[indexKabupaten]; // Set the city object
                        await this.kecamatan_index(this.kabupatenList[indexKabupaten].id); // Load district (kecamatan) list for the city

                        // Find the index of the district (kecamatan) in the district list
                        const indexKecamatan = this.kecamatanList.findIndex((record) => record.text === currentAddress.district);
                        if (indexKecamatan > -1) {
                            currentAddress.district = this.kecamatanList[indexKecamatan]; // Set the district object
                            await this.kelurahan_index(this.kecamatanList[indexKecamatan].id); // Load sub-district (kelurahan) list for the district

                            // Find the index of the sub-district (kelurahan) in the sub-district list
                            const indexKelurahan = this.kelurahanList.findIndex((record) => record.text === currentAddress.sub_district);
                            if (indexKelurahan > -1) {
                                currentAddress.sub_district = this.kelurahanList[indexKelurahan]; // Set the sub-district object
                            }
                        }
                    }
                }
            }

            this.statusLoad = false;

            // await this.provinsi_index();

            // // console.log(this.provinsiList)
            // // console.log(this.currentData.province)
            // const indexprovinsi = this.provinsiList.findIndex((record) => record.text === this.currentData.province);
            // // console.log(indexprovinsi)
            // // console.log(this.provinsiList[0])
            // // console.log(this.provinsiList[indexprovinsi])

            // if (indexprovinsi > -1) {
            //     this.currentData.province = this.provinsiList[indexprovinsi]
            //     await this.kabupaten_index(this.provinsiList[indexprovinsi].id);
            // }

            // // console.log(this.kabupatenList)
            // // console.log(this.currentData.city)
            // const indexkabupaten = this.kabupatenList.findIndex((record) => record.text === this.currentData.city);
            // // console.log(indexkabupaten)
            // // console.log(this.kabupatenList[0])
            // // console.log(this.kabupatenList[indexkabupaten])
            // if (indexkabupaten > -1) {
            //     this.currentData.city = this.kabupatenList[indexkabupaten]
            //     await this.kecamatan_index(this.kabupatenList[indexkabupaten].id);
            // }

            // const indexkecamatan = this.kecamatanList.findIndex((record) => record.text === this.currentData.district);
            // // console.log(indexkecamatan)
            // if (indexkecamatan > -1) {
            //     this.currentData.district = this.kecamatanList[indexkecamatan]
            //     await this.kelurahan_index(this.kecamatanList[indexkecamatan].id);
            // }

            // const indexkelurahan = this.kelurahanList.findIndex((record) => record.text === this.currentData.sub_district);
            // // console.log(indexkecamatan)
            // if (indexkelurahan > -1) {
            //     this.currentData.sub_district = this.kelurahanList[indexkelurahan]
            //     // await this.kelurahan_index(this.kelurahanList[indexkelurahan].id);
            // }

            // for amount currency
            // this.records.total_deposit = rupiahInput(this.records.total_deposit);
            // this.currentData.deposit.forEach((item) => {
            //     item.amount = rupiahInput(item.amount);
            // });
            // console.log(this.currentData.deposit.lenght)
            if (!this.currentData.deposit || this.currentData.deposit.length === 0) {
                // If deposit length is 0, add one default data object
                this.currentData.deposit = [{
                    date: new Date().toISOString().split('T')[0],  // Current date
                    amount: 'Rp 0,-',  // Default amount
                    // Add any other necessary default properties
                }];
            } else {
                // If deposit length is not 0, apply rupiahInput to each deposit
                this.currentData.deposit.forEach((item) => {
                    item.amount = rupiahInput(item.amount || 'Rp 0,-');  // Ensure proper formatting even if some amounts are missing
                });
            }

        },


        // goBack() {
        //     // Retrieve the previous route from localStorage
        //     const previousRoute = localStorage.getItem('previousRoute');
        //     if (previousRoute) {
        //         // Navigate to the previous route
        //         this.$router.push(previousRoute);
        //         // Optionally clear the saved route
        //         localStorage.removeItem('previousRoute');
        //     } else {
        //         // Fallback route if no previous route is found
        //         this.$router.push({ name: 'dashboard' });
        //     }
        // },


    },
}
</script>