<template>
    <NavBar />
    <div class=" justify-center mt-5 px-10">
        <div class="mb-12 flex justify-center">
            <h2 class="text-5xl font-redHat font-bold">Terima Barang</h2>
        </div>
        <form @submit.prevent="insert">
            <div class="mb-20 px-6">
                <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-4 mb-4">
                    <div class="">
                        <label class="block font-redHat text-[#8F   8F8F] font-medium font-2xl mb-2">Supplier</label>
                        <input type="text" v-model="currentData.supplier_name"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" required
                            disabled>
                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F   8F8F] font-medium font-2xl mb-2">Code</label>
                        <input type="text" v-model="currentData.code"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" required
                            disabled>
                    </div>
                </div>
                <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-4 mb-4">
                    <div class="">
                        <label class="block font-redHat text-[#8F   8F8F] font-medium font-2xl mb-2">Varian</label>
                        <input type="text" v-model="currentData.color"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" required
                            disabled>
                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F   8F8F] font-medium font-2xl mb-2">Size</label>
                        <input type="text" v-model="currentData.size"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" required
                            disabled>
                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F   8F8F] font-medium font-2xl mb-2">QTY yang
                            dibeli</label>
                        <input type="text" v-model="currentData.qty"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" required
                            disabled>
                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F   8F8F] font-medium font-2xl mb-2">QTY yang
                            diterima</label>
                        <input type="number" v-model="currentData.qty_buy"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" placeholder="" required
                            :max="currentData.qty">
                    </div>
                    <div class="">
                        <div class="flex justify-between align-middle">
                            <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Status Sisa
                                Orderan</label>
                            <!-- <button type="button" @click="AddSupplier()">
                                <i class='bx bx-plus-circle text-xl'></i>
                            </button> -->
                        </div>
                        <!-- <input type="text" v-model="currentData.supplier_name" class="block w-full border-1 border-[#5FBAE6] rounded-md p-2"  placeholder="" required> -->
                        <multiselect v-model="currentData.status_buy" :options="StatusPesananList"
                            :custom-label="nameWithLang" placeholder="" label="name" track-by="" :selectLabel="''"
                            :deselectLabel="''" class="multiselect-full-width"
                            v-show="currentData.qty_buy < currentData.qty"></multiselect>

                    </div>
                </div>
            </div>
            <div class="mb-5 space-x-5 flex justify-end px-10">
                <button type="button" @click="cancel"
                    class="bg-white text-xl font-redHat text-black text-center py-2 px-4 rounded font-bold border border-[#5FBAE6] w-[240px]">
                    Batal</button>
                <button type="submit"
                    class="bg-[#F5CA40] text-black text-xl font-redHat text-center py-2 px-4 rounded font-bold w-[240px]">Simpan</button>
            </div>
        </form>
    </div>
    <!-- modal delete -->
    <div v-if="isModalOpen" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <!-- Modal content -->
        <div class="bg-white rounded-lg shadow-lg p-6 max-w-md w-full" @click.stop>
            <div class="flex justify-between items-center mb-4">
                <h2 class="text-xl font-semibold">Delete Produk</h2>
                <button @click="closeModal" class="text-red-600 hover:text-gray-900">
                    ✕
                </button>
            </div>
            <p class="text-gray-700 mb-10">
                Apakah anda yakin ingin menghapus data ini ?
            </p>

            <div class="space-y-3">
                <div aria-hidden="true" class="border-t px-2"></div>
                <div class="button-group flex justify-end">
                    <button @click="closeModal"
                        class="bg-gray-400 text-white font-bold py-2 px-4 rounded hover:bg-gray-700 me-3">
                        Close
                    </button>
                    <button @click="confirmDelete"
                        class="bg-red-500 text-white font-bold py-2 px-4 rounded hover:bg-red-700">
                        Delete
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.text-xs {
    font-size: 11px !important;
}
</style>

<script>
import NavBar from '@/components/NavBar.vue';
import axios from 'axios';
import { ErrorConnectionTimeOut, goPage, ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom, rupiahInput, rupiahoutput, currencyInput } from "@/assets/js/function";
// import {ShowLoading, CloseLoading} from "@/assets/js/function";
import Cookies from "js-cookie";
import Multiselect from 'vue-multiselect';
import { setLocale } from 'yup';


export default {
    components: {
        NavBar,
        Multiselect,
    },

    data() {
        return {
            currentData: {
                qty: 0,
                qty_buy: 0,
            },
            StatusPesananList: [
                { name: "PENDING" },
                { name: "SOLDOUT" },
            ],
        };
    },

    created() {
        const token = Cookies.get("token");
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 30000,
        }



        this.currentData = JSON.parse(localStorage.getItem('currentData'));

        this.currentData.status_buy = { name: 'PENDING' };

    },

    updated() {
        this.statusLoad = true;
    },
    // watch: {
    //     'currentData.product_detail': {
    //         handler(newValue) {
    //             // Deep clone of the array to avoid affecting original data
    //             let size_fix = [];

    //             if (newValue && newValue[0] && Array.isArray(newValue[0].sizeqty)) {
    //                 let clonedSizeQty = JSON.parse(JSON.stringify(newValue[0].sizeqty));

    //                 // You can perform any operations on clonedSizeQty
    //                 clonedSizeQty.forEach((item) => {
    //                     let item_prepare = { ...item };  // clone each item
    //                     item_prepare.qty = 0;
    //                     size_fix.push(item_prepare);
    //                 });
    //             }

    //             if (this.statusLoad == true) {
    //                 localStorage.setItem('product_size', JSON.stringify(size_fix));
    //             }
    //         },
    //         deep: true
    //     },
    // },

    methods: {

        nameWithLang({ name }) {
            return `${name}`
        },
        cancel() {
            goPage('summaryreport');
        },

        insert() {
            // Check if id_supplier is null or undefined
            if (!this.currentData.status_buy || !this.currentData.status_buy.name) {
                AlertPopup("error", "", "Status harus diisi", 1500, false); // Show alert if id_supplier is missing
                return; // Stop the function from proceeding
            }

            ShowLoading();
            let formData = new FormData();

            Object.entries(this.currentData).forEach(([key, value]) => {
                formData.append(key, value);
            });

            formData.delete("status_buy");
            formData.append("status_buy", this.currentData.status_buy.name)
            formData.append("id_preorder", localStorage.getItem('id_preorder'))

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'accept';
            } else {
                this.api = base_url + 'accept';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        AlertBottom(response.data.message);
                        CloseLoading();
                        goPage("summaryreport");
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },

    },
}
</script>