<template>
    <NavBar />
    <div class=" justify-center mt-5 px-10">
        <div class="mb-12 flex justify-center">
            <h2 class="text-[50px] font-bold">Discount</h2>
        </div>
        <form @submit.prevent="insert">
            <div class="mb-20 px-6 mx-3">
                <div class="mb-4">
                    <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Product</label>
                    <multiselect v-model="currentData.id_product" tag-placeholder="Add this as new tag"
                        placeholder="Search or add a tag" label="name" track-by="id" :options="ProductList"
                        :multiple="true" :taggable="true" @tag="addTag"></multiselect>
                </div>
                <div class="grid grid-cols-4 gap-4">
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Qty</label>
                        <input type="number" v-model="currentData.qty" min="1"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="instagram"
                            placeholder="" required>
                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Qty Maksimal</label>
                        <input type="number" v-model="currentData.qty_max" :min="currentData.qty + 1"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="instagram"
                            placeholder="" required>
                    </div>

                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Jumlah</label>
                        <input type="text" v-model="currentData.amount"
                            @input="formatCurrency('amount', currentData.amount)"
                            class="block w-full border-1 border-[#5FBAE6] rounded-md p-2" name="name" placeholder=""
                            required>
                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Tipe</label>
                        <multiselect v-model="currentData.type" :options="TypeList" :custom-label="nameWithLang"
                            style="border: 1px solid #5FBAE6 !important" placeholder="Pilih Tipe Diskon" label="name"
                            track-by="name" :selectLabel="''" :deselectLabel="''"
                            class=" border border-[#5FBAE6] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full font-redHat font-medium text-[#5FBAE6]">
                        </multiselect>
                    </div>
                    <div class="">
                        <label class="block font-redHat text-[#8F8F8F] font-medium font-2xl mb-2">Tipe Customer</label>
                        <multiselect v-model="currentData.type_discount" :options="TypeCustomerList"
                            :custom-label="nameWithLang" style="border: 1px solid #5FBAE6 !important"
                            placeholder="Pilih Tipe Diskon" label="name" track-by="name" :selectLabel="''"
                            :deselectLabel="''"
                            class=" border border-[#5FBAE6] text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-full font-redHat font-medium text-[#5FBAE6]">
                        </multiselect>
                    </div>
                </div>
            </div>

            <div class="mb-3 space-x-5 flex justify-end px-10">
                <RouterLink to="discount"
                    class="bg-white text-[20px] text-black text-center py-2 px-4 rounded font-medium border border-[#5FBAE6] w-[240px]">
                    Batal</RouterLink>
                <button type="submit"
                    class="bg-[#F5CA40] text-black text-[20px] text-center py-2 px-4 rounded font-medium w-[240px]">Simpan</button>
            </div>
        </form>


    </div>
</template>

<style scoped></style>

<script>
import NavBar from '@/components/NavBar.vue';
import axios from 'axios';
import { ErrorConnectionTimeOut, goPage, ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom, rupiah, rupiahInput, rupiahoutput } from "@/assets/js/function";
import Cookies from "js-cookie";
import Multiselect from 'vue-multiselect';



export default {
    components: {
        NavBar,
        Multiselect,

    },

    data() {
        return {
            currentData: {
                // name: '',
                amount: 0,
            },
            dataSize: [
                { name: "FS" },
            ],
            TypeList: [
                { name: "TOTAL", value: "TOTAL" },
                { name: "ITEM", value: "ITEM" },
            ],
            TypeCustomerList: [
                { name: "RESELLER", value: "RESELLER" },
                { name: "REGULER", value: "REGULER" },
            ],
            ProductList: [],

            // newRecord: '',
        };
    },

    created() {
        const token = Cookies.get("token");
        // console.log(token)
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 30000,
        }

        this.ProductList = JSON.parse(localStorage.getItem('data_product'));
        this.currentData.amount = rupiahInput(this.currentData.amount);
        this.prepareData();

    },

    methods: {
        addTag(newTag) {
            const tag = {
                name: newTag,
                code: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }
            this.currentData.id_product.push(tag)
        },
        prepareData() {
            this.currentData = JSON.parse(localStorage.getItem('currentData'));
            this.currentData.amount = rupiah(this.currentData.amount);

            const arrProduct = this.currentData.id_product.split(',');

            let arrayProduct = [];
            arrProduct.forEach((value) => {

                const index = this.ProductList.findIndex((record) => record.id == value);
                arrayProduct.push(this.ProductList[index]);
            });

            this.currentData.id_product = arrayProduct;

            const indexType = this.TypeList.findIndex((record) => record.value === this.currentData.type);
            this.currentData.type = this.TypeList[indexType];

            const indexTypeCustomer = this.TypeCustomerList.findIndex((record) => record.value === this.currentData.type_discount);

            this.currentData.type_discount = this.TypeCustomerList[indexTypeCustomer];
        },
        nameWithLang({ name }) {
            return `${name}`
        },
        formatCurrency(label, number) {
            this.currentData[label] = rupiahInput(number);

        },
        focusNextInput(index) {
            // Get the next input element
            const nextInput = this.$refs['input' + (index + 1)];
            if (nextInput && nextInput[0]) {
                nextInput[0].focus();  // Focus the first element in the array
            }
        },
        checkingLastRowProductDetail(index) {
            if (index == this.dataSize.length - 1) {
                // this.records[index].isAdded = true;
                return true;

                // this.records.splice(index + 1, 0, );
            } else {
                // this.records[index].isAdded = false;
                return false;

            }
        },
        checkingMaxRowProductDetail(index) {
            if (this.dataSize.length == 19) {
                // this.records[index].isAdded = true;
                return false;
                // this.records.splice(index + 1, 0, );
            } else {
                // this.records[index].isAdded = false;
                return true;

            }
        },
        checkingMinRowProductDetail(index) {
            if (this.dataSize.length == 1) {
                return false;
            } else {
                return true;
            }
        },
        addProductSize(index) {
            this.dataSize.push({
                size: '',
                qty: 0
            });
            this.$nextTick(() => {
                this.focusNextInput(index);
            });

        },
        deleteProductSize(index) {
            this.dataSize.splice(index, 1);
        },
        insert() {
            ShowLoading();
            let formData = new FormData();

            Object.entries(this.currentData).forEach(([key, value]) => {
                formData.append(key, value);
            });

            const resultString = this.currentData.id_product.map(item => item.id).join(",");
            formData.delete('id_product');
            formData.append('id_product', resultString);

            formData.delete('type');
            formData.append('type', this.currentData.type.value);

            formData.delete('type_discount');
            formData.append('type_discount', this.currentData.type_discount.value);

            formData.delete('amount');
            formData.append('amount', rupiahoutput(this.currentData.amount));

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'discount_update';
            } else {
                this.api = base_url + 'discount_update';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    if (response.data.status == true) {
                        AlertBottom(response.data.message);
                        goPage("discount");
                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
    },
}
</script>