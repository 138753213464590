<template>
    <NavBar />
    <div class=" justify-center mt-5 px-10">
        <div class="mb-12 flex justify-center">
            <h2 class="text-[50px] font-bold">Kategori Produk</h2>
        </div>
        <form @submit.prevent>
            <div class="mb-5 px-10">
                <label class="mb-3 block text-base font-normal text-gray-400">
                    Kategori Produk
                </label>
                <input type="text" v-model="currentData.name" id="kategoriProduk" placeholder=""
                    class="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#F5CA40] focus:shadow-md"
                    required />
            </div>
            <div class="mb-20 px-10">
                <label class="mb-3 block text-base font-normal text-gray-400">
                    Ukuran
                </label>
                <div class="inline-block" v-for="(item, index) in dataSize" :key="index">
                    <div class="d-flex">
                        <div>
                            <input type="text" v-model="item.name"
                                class=" w-14 border border-[#e0e0e0] rounded-md p-2 me-2 text-xl text-center text-black focus:border-[#F5CA40] focus:shadow-md outline-none"
                                name="size" placeholder="" @keyup.enter="addProductSize(index);" :ref="'input' + index"
                                required>
                        </div>

                        <div class="flex flex-column">
                            <div v-show="checkingLastRowProductDetail(index) && checkingMaxRowProductDetail(index)"
                                @click="addProductSize(index);" class="cursor-pointer">
                                <i class="bi bi-plus-circle text-[15px] text-[#5FBAE6]"></i>
                            </div>
                            <div v-show="checkingLastRowProductDetail(index) && checkingMinRowProductDetail(index)"
                                @click="deleteProductSize(index);" class="cursor-pointer">
                                <i class="bi bi-trash text-danger"></i>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="mb-3 space-x-5 flex justify-end px-10">
                <RouterLink to="kategoriproduk"
                    class="bg-white text-[20px] text-black text-center py-2 px-4 rounded font-medium border border-[#5FBAE6] w-[240px]">
                    Batal</RouterLink>
                <button type="button" @click="insert"
                    class="bg-[#F5CA40] text-black text-[20px] text-center py-2 px-4 rounded font-medium w-[240px]">Simpan</button>
            </div>
        </form>


    </div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import axios from 'axios';
import { ErrorConnectionTimeOut, goPage, ShowLoading, CloseLoading, base_url, AlertPopup, AlertBottom } from "@/assets/js/function";
import Cookies from "js-cookie";


export default {
    components: {
        NavBar,
    },

    data() {
        return {
            currentData: [
                // name: '',
            ],
            dataSize: [
                { name: "FS" },
            ],
            // newRecord: '',
        };
    },

    created() {
        const token = Cookies.get("token");
        // console.log(token)
        this.config = {
            headers: { Authorization: `Bearer ` + token },
            timeout: 30000,
        }




    },

    methods: {
        focusNextInput(index) {
            // Get the next input element

            const nextInput = this.$refs['input' + (index + 1)];

            if (nextInput && nextInput[0]) {
                nextInput[0].focus();  // Focus the first element in the array
            }
        },
        checkingLastRowProductDetail(index) {
            if (index == this.dataSize.length - 1) {
                // this.records[index].isAdded = true;
                return true;

                // this.records.splice(index + 1, 0, );
            } else {
                // this.records[index].isAdded = false;
                return false;

            }
        },
        checkingMaxRowProductDetail(index) {
            if (this.dataSize.length == 19) {
                // this.records[index].isAdded = true;
                return false;

                // this.records.splice(index + 1, 0, );
            } else {
                // this.records[index].isAdded = false;
                return true;

            }
        },
        checkingMinRowProductDetail(index) {
            if (this.dataSize.length == 1) {

                return false;


            } else {

                return true;

            }
        },
        addProductSize(index) {
            this.dataSize.push({
                size: '',
                qty: 0
            });
            this.$nextTick(() => {
                this.focusNextInput(index);
            });

        },
        deleteProductSize(index) {
            this.dataSize.splice(index, 1);
        },


        insert() {
            ShowLoading();
            let formData = new FormData();

            Object.entries(this.currentData).forEach(([key, value]) => {
                formData.append(key, value);
            });


            formData.delete("size");
            const sizeString = this.dataSize.map(item => item.name).join(", ");
            formData.append('size', sizeString);

            if (process.env.NODE_ENV === "development") {
                this.api = base_url + 'category_insert';
            } else {
                this.api = base_url + 'category_insert';
            }

            axios
                .post(this.api, formData, this.config)
                .then((response) => {
                    // console.log(response)
                    if (response.data.status == true) {
                        AlertBottom(response.data.message);
                        goPage("kategoriproduk");
                        CloseLoading();
                    }
                    if (response.data.status == false) {
                        AlertPopup("error", "", response.data.message, 1500, false);
                    }
                })
                .catch((error) => {
                    ErrorConnectionTimeOut(error);
                });
        },
    },
}
</script>